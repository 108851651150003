/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { Fragment } from "react";
import { logoBlack, people } from "../assets/images";
import { H1, H2, MainContainer } from "../components";
import { Column, Row } from "../components/flex-box";
import { Footer } from "../components/Footer";
import { desktop, mobile } from "../utils";

export const DemandLinkedPage = () => {
  return (
    <Fragment>
      <MainContainer
        moveUp
        background="linear-gradient(180deg,#859bff 0%,#81e3c7 38%,#ffa9e8 75%)"
        staticContainer={true}
      >
        <Row
          css={css`
            padding-top: 60px;
            background-color: #fff;
            border-radius: 50px;
            padding: 30px;
          `}
        >
          <Column
            css={css`
              margin: 0;
              padding-right: 100px;
              ${mobile} {
                padding: 0;
                align-items: center;
              }
            `}
          >
            <img
              css={css`
                ${desktop} {
                  display: none;
                }
              `}
              src={logoBlack}
              alt=""
            />
            <img
              css={css`
                width: 100%;
                ${mobile} {
                  margin: 40px auto 0;
                }
              `}
              src={people}
              alt=""
            />
          </Column>
          <Column
            css={css`
              margin: 0;
              ${mobile} {
                align-items: center !important;
                * {
                  text-align: center;
                }
              }
            `}
          >
            <img
              css={css`
                ${mobile} {
                  display: none;
                }
              `}
              src={logoBlack}
              alt=""
            />
            <H1>Rekening gelinkt aan je account!</H1>
            <H2>We sturen je per mail een bevestiging</H2>
          </Column>
        </Row>
      </MainContainer>
      <Footer />
    </Fragment>
  );
};
