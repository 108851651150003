export class Api {
  public isAuthenticated = false;

  authenticate(cb: () => any) {
    this.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  }

  signOut(cb: () => any) {
    this.isAuthenticated = false;
    setTimeout(cb, 100); // fake async
  }
}
