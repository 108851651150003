import { css, Global } from "@emotion/core";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MainContainer, PrivateRoute } from "./components";
import { Api } from "./modules/api";
import { AppContext } from "./modules/core";
import * as firebase from "./modules/firebase";
import { AccountCreated } from "./pages/AccountCreated";
import Admin from "./pages/admin";
import { CreateAccountPage } from "./pages/CreateAccount";
import { DeepLinkPage } from "./pages/Deep-link";
import Demand from "./pages/Demand";
import { DemandLinkedPage } from "./pages/DemandLinked";
import { Development } from "./pages/Development";
import Login from "./pages/Login";
import Thanks from "./pages/Thanks";

const api = new Api();

export default function App() {
  return (
    <AppContext.Provider value={{ api, firebase }}>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            font-family: "Nunito sans", sans-serif;
          }
          button {
            display: inline-block;
            border-radius: 4px;
            border: 1px solid #dddddd;
            background-color: #ffffff;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            padding: 6px 12px;
            cursor: pointer;
            &:hover {
              background: #000000;
              color: #ffffff;
            }
          }
          @font-face {
            font-family: "Byrd";
            src: url("/fonts/Byrd-ExtraBold.woff2") format("woff2"),
              url("/fonts/Byrd-ExtraBold.woff") format("woff");
            font-weight: bold;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: "Byrd";
            src: url("/fonts/Byrd-Black.woff2") format("woff2"),
              url("/fonts/Byrd-Black.woff") format("woff");
            font-weight: 900;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: "Byrd";
            src: url("/fonts/Byrd-Medium.woff2") format("woff2"),
              url("/fonts/Byrd-Medium.woff") format("woff");
            font-weight: 500;
            font-style: normal;
            font-display: swap;
          }

          @font-face {
            font-family: "Byrd";
            src: url("/fonts/Byrd-Regular.woff2") format("woff2"),
              url("/fonts/Byrd-Regular.woff") format("woff");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
        `}
      />
      <Router>
        <Switch>
          <Route
            path="/demand/:demandId/payment/thanks"
            children={<Thanks />}
          />
          <Route path="/demand/:demandId" children={<Demand />} />
          <Route
            path="/create-account/:demandId"
            children={<CreateAccountPage />}
          />
          <Route path="/account-created/" children={<AccountCreated />} />
          <Route path="/demand-linked/" children={<DemandLinkedPage />} />
          <PrivateRoute path="/admin" children={<Admin />} />
          <Route path="/login" children={<Login />} />
          <Route path="/dev" children={<Development />} />
          <Route path="/deep-link" children={<DeepLinkPage />} />
          <Route exact path="/">
            <MainContainer>nothing to see here</MainContainer>
          </Route>
        </Switch>
      </Router>
    </AppContext.Provider>
  );
}
