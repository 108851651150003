import styled from "@emotion/styled";

export const Dropdown = styled.div`
  color: #000000;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  position: relative;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;
