import styled from "@emotion/styled";

export const NavBar = styled.nav`
  height: 73px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  background-color: #ffffff;
  padding: 0px 12px;
`;
