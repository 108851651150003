import styled from "@emotion/styled";
import React, { useState } from "react";
import { caretRight, iconUser } from "../../assets/images";
import { Container, Layout } from "../../components";

const SearchBar = styled.div`
  width: 655px;
  height: 55px;
  border-radius: 5px;
  background-color: #dddddd;
  margin-bottom: 14px;
`;

const ListItem = styled.div`
  display: block;
  text-decoration: none;
  color: #000;
  width: 655px;
  height: 55px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 15px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;
const Name = styled.span`
  width: 221px;
  height: 25px;
  color: #000000;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 700;
  margin: 0 16px;
`;
const ListItemDataContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ListItemData = styled.div`
  display: inline-block;
  margin: 0 16px;
`;

const Sidebar = styled.div`
  padding: 42px 10px 23px;
  width: 320px;
  background: white;
`;

const VoornaamAchternaam = styled.div`
  color: #000000;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0px;
    }
  }
  &.padded {
    padding: 20px 10px;
  }
`;

const UserIconImage = styled.img`
  width: 54px;
  height: 54px;
`;

const CaretIcon = styled.img`
  width: 8px;
  height: 13px;
  margin-left: 34px;
`;

const Line = styled.div`
  border-bottom: 1px solid #e3e3e3;
`;

const Table = styled.table`
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
  &.bold {
    font-size: 18px;
    font-weight: 700;
  }
  td {
    margin-bottom: 8px;
  }
  td.alignRight {
    text-align: right;
  }
`;

const Label = styled.span`
  border-radius: 2px;
  background-color: #ececec;
  padding: 4px;
`;

const Bold = styled.div`
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
`;

export const PaymentList = () => {
  const [selectedSettlement, setSelectedSettlement] = useState<number | null>(
    null
  );
  const [selectedDemand, setSelectedDemand] = useState<number | null>(0);

  const onSelectSettlement = (index: number) => {
    setSelectedSettlement(index);
    setSelectedDemand(null);
  };
  const onSelectDemand = (index: number) => {
    setSelectedSettlement(null);
    setSelectedDemand(index);
  };

  return (
    <Layout className="center">
      <Container>
        <h1>Inkomsten overzicht</h1>
        <SearchBar />
        <ListItem onClick={() => onSelectSettlement(0)}>
          <ListItemDataContainer>
            <Name>Augustus</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Betaald op 24-09</ListItemData>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <Bold>€ 440</Bold>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>
        <ListItem onClick={() => onSelectSettlement(0)}>
          <ListItemDataContainer>
            <Name>Juli</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Betaald op 24-08</ListItemData>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <Bold>€ 440</Bold>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>
        <ListItem onClick={() => onSelectSettlement(0)}>
          <ListItemDataContainer>
            <Name>Juni</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Betaald op 24-07</ListItemData>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <Bold>€ 440</Bold>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>

        <h1>Studenten</h1>
        <SearchBar />
        <ListItem onClick={() => onSelectDemand(0)}>
          <ListItemDataContainer>
            <Name>Voornaam Achternaam</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Studentnr: 345603847</ListItemData>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>
        <ListItem onClick={() => onSelectDemand(0)}>
          <ListItemDataContainer>
            <Name>Voornaam Achternaam</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Studentnr: 345603847</ListItemData>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>
        <ListItem onClick={() => onSelectDemand(0)}>
          <ListItemDataContainer>
            <Name>Voornaam Achternaam</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Studentnr: 345603847</ListItemData>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>
        <ListItem onClick={() => onSelectDemand(0)}>
          <ListItemDataContainer>
            <Name>Voornaam Achternaam</Name>
          </ListItemDataContainer>
          <ListItemDataContainer>
            <ListItemData>Studentnr: 345603847</ListItemData>
            <CaretIcon src={caretRight} alt="" />
          </ListItemDataContainer>
        </ListItem>
      </Container>
      {selectedDemand !== null ||
        (selectedSettlement !== null && (
          <Sidebar>
            <Row className="padded">
              <UserIconImage src={iconUser} />
              <VoornaamAchternaam>Voornaam Achternaam</VoornaamAchternaam>
            </Row>
            <Line></Line>
            <Row className="padded">
              <Table>
                <tbody>
                  <tr>
                    <td>Studentnr:</td>
                    <td>345603847</td>
                  </tr>
                  <tr>
                    <td>Debiteurnr:</td>
                    <td>345603847</td>
                  </tr>
                  <tr>
                    <td>Factuurnr:</td>
                    <td>345603847-23</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Line></Line>
            <Row className="padded">
              <Table className="bold">
                <tbody>
                  <tr>
                    <td>Factuurbedrag:</td>
                    <td>€ 240</td>
                  </tr>
                  <tr>
                    <td>Saldo betaald:</td>
                    <td>€ 40</td>
                  </tr>
                  <tr>
                    <td>Saldo gestorneerd:</td>
                    <td>€ 20</td>
                  </tr>
                </tbody>
              </Table>
            </Row>
            <Line></Line>
            <Row className="padded">
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <Bold>Details:</Bold>
                    </td>
                  </tr>
                  <tr>
                    <td>Augustus:</td>
                    <td>€ 20</td>
                    <td className="alignRight">
                      <Label>Betaald</Label>
                    </td>
                  </tr>
                  <tr>
                    <td>September:</td>
                    <td>€ 20</td>
                    <td className="alignRight">
                      <Label>Gestorneerd</Label>
                    </td>
                  </tr>
                  <tr>
                    <td>Oktober:</td>
                    <td>€ 20</td>
                    <td className="alignRight">
                      <Label>Betaald</Label>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Row>
          </Sidebar>
        ))}
    </Layout>
  );
};
