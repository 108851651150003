import { css, cx } from "emotion";
import React, { FC } from "react";
import { FaChevronRight } from "react-icons/fa";

export const ActionButton: FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > & {
    icon?: React.ReactNode;
    chevron?: boolean;
    noMargin?: boolean;
    disabled?: boolean;
  }
> = ({ icon, chevron, className, children, noMargin, disabled, ...props }) => (
  <div
    className={cx(
      css`
        width: 300px;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        background-color: #ffffff;
        padding: 18px 20px;
        color: ${disabled ? "#eeeeee" : "#000000"};
        font-family: "Nunito Sans";
        font-size: 18px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin: ${noMargin ? "0px" : "12px"};
        cursor: ${disabled ? "auto" : "pointer"};
        ${!disabled &&
        `
          &:hover {
            background: #555;
            color: #ffffff;
          }
        `}
        @media screen and (max-width: 640px) {
          width: 100%;
        }
      `,
      className
    )}
    {...props}
  >
    {icon && icon}
    <div
      className={css`
        flex: 1;
        margin-left: ${icon ? "16px" : "0px"};
        margin-right: ${chevron ? "16px" : "0px"};
      `}
    >
      {children}
    </div>
    {chevron && <FaChevronRight />}
  </div>
);
