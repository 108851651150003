/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import axios from "axios";
import classNames from "classnames";
import { compact, flatten, padEnd } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import { Dots } from "react-activity";
import ReactJson from "react-json-view";
import { useParams } from "react-router-dom";
import { H1, MainContainer } from "../../components";
import { FlexBox, Row, RowReverse } from "../../components/flex-box";
import { environment } from "../../modules/config";
import { AppContext } from "../../modules/core";
import { db } from "../../modules/firebase";
import { Debitor, Demand, Payment } from "../../shared";
import { getDocument, getDocuments } from "../../utils";

const MONTHS = [
  "Januari",
  "Februari",
  "Maart",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Augustus",
  "September",
  "Oktober",
  "November",
  "December",
];

const StyledH1 = styled(H1)`
  color: #fff;
`;

const getDemandCategory = (key: string) => {
  const PAYMENT_CATEGORIES: { [key: string]: string } = {
    studyBooks: "Schoolboeken",
  };
  if (PAYMENT_CATEGORIES[key]) {
    return PAYMENT_CATEGORIES[key];
  }
  return key;
};

const ListItem = styled.div`
  display: block;
  text-decoration: none;
  color: #fff;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 14px;
  padding: 11px 17px 27px;
  cursor: pointer;

  background-color: #ccc4;
  &:hover {
    background-color: #5554;
    color: #fff;
  }
  &.selected {
    background-color: #fff;
    color: #000;
  }
`;
const ListItemTitle = styled.span`
  /* width: 221px; */
  height: 25px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 700;
`;
const ListItemDataContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ListItemData = styled.div`
  display: inline-block;
`;

const SidebarTitle = styled.div`
  color: #000000;
  font-family: "Nunito Sans";
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
`;

const UserData = styled.div`
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  margin-right: 16px;
`;

type LineProps = {
  noMargin?: boolean;
};
const Line = styled.div<LineProps>`
  border-bottom: 1px solid #fff4;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0px" : "16px")};
`;

const Table = styled.table`
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  border-collapse: separate;
  border-spacing: 8px 8px;
  width: 100%;
  margin: 0 -8px;
  &.bold {
    font-size: 18px;
    font-weight: 700;
  }
  td {
  }
  td.alignRight {
    text-align: right;
  }
  td.alignCenter {
    text-align: center;
  }
  .l {
    font-size: 18px;
  }
  .sm {
    font-size: 14px;
  }
  .xs {
    font-size: 12px;
  }
  .xxs {
    font-size: 10px;
  }
`;

const Label = styled.span`
  border-radius: 2px;
  background-color: #818181;
  padding: 4px 8px;
  width: 57px;
  height: 14px;
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  &.light {
    background-color: #c2c2c2;
  }
`;

const ProgressBar = styled(RowReverse)`
  height: 32px;
  border-radius: 11px;
  background-color: #0002;
  position: relative;
  overflow: hidden;
  color: #fff;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;

  .selected & {
    background-color: #0005;
  }
`;

const ProgressBarFill = styled.div`
  background-color: #0004;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  color: white;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-left: 12px;
`;

function roundNumber(input: number) {
  return Math.round(input * 100) / 100;
}

function roundString(input: number) {
  const roundedString = (Math.round(input * 100) / 100).toString(10);
  const parts = roundedString.split(".");
  return `${parts[0]}.${parts[1] ? padEnd(parts[1], 2, "0") : "00"}`;
}

type Params = {
  debitorId: string;
};

export const DebitorDetail = () => {
  const [selectedDemand, setSelectedDemand] = useState<number | null>(null);

  const { debitorId } = useParams<Params>();
  const { firebase } = useContext(AppContext);
  const [debitor, setDebitor] = useState<FirestoreDocument<Debitor>>();
  const [demands, setDemands] = useState<FirestoreDocument<Demand>[]>();
  const [payments, setPayments] = useState<FirestoreDocument<Payment>[] | null>(
    null
  );
  const [paymentsLoaded, setPaymentsLoaded] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState();
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [showExtraInfoSidebar, setShowExtraInfoSidebar] = useState(false);
  const [amount, setAmount] = useState(1);
  const [sequenceType, setSequenceType] = useState("oneoff");
  const [method, setMethod] = useState("ideal");
  const [customerId, setCustomerId] = useState("cst_H86y3vq2Du");

  const [comments, setComments] = useState<string>();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const getDebitor = async () => {
      if (!firebase.db) {
        return;
      }
      try {
        const debitor = await getDocument<Debitor>(
          firebase.db,
          "debitors",
          debitorId
        );
        const demands = await getDocuments<Demand>(
          firebase.db.collection("demands").where("debitorId", "==", debitorId)
        );
        setLoaded(true);
        setDebitor(debitor);
        setDemands(demands);
        setComments(debitor.data.comments);
        if (demands.length) {
          setSelectedDemand(0);
        }
      } catch (e: any) {
        setError(e.message);
        setLoaded(true);
        console.error(e);
      }
    };
    getDebitor();
  }, [firebase.db, debitorId]);

  useEffect(() => {
    const getMolliePayments = async () => {
      if (!firebase.db || !demands || selectedDemand === null) {
        return;
      }
      try {
        const payments = await getDocuments<Payment>(
          firebase.db
            .collection("payments")
            .where("paymentProvider", "==", "mollie")
            .where("metadata.demandId", "==", demands[selectedDemand].id)
            .orderBy("createdAt")
        );
        return payments;
      } catch (e) {
        console.error(e);
      }
    };
    const getOPPPayments = async () => {
      if (!firebase.db || !demands || selectedDemand === null) {
        return;
      }
      try {
        const payments = await getDocuments<Payment>(
          firebase.db
            .collection("payments")
            .where("paymentProvider", "==", "opp")
            .where(
              "convertedMetadata.demandId",
              "==",
              demands[selectedDemand].id
            )
            .orderBy("created")
        );
        return payments;
      } catch (e) {
        console.error(e);
      }
    };
    setPayments(null);
    setPaymentsLoaded(false);
    Promise.all([getMolliePayments(), getOPPPayments()]).then((payments) => {
      if (payments) {
        setPayments(flatten(compact(payments)));
      }
      setPaymentsLoaded(true);
    });
    if (!demands || selectedDemand == null) {
      return;
    }
    const demand = demands[selectedDemand];
    setAmount(
      Math.random() * (demand.data.totalAmount - demand.data.paidAmount)
    );
  }, [selectedDemand, firebase.db, demands]);

  const saveComments = () => {
    if (!debitor || typeof comments === "undefined") {
      return;
    }
    setSaving(true);
    db.collection("debitors")
      .doc(debitor.id)
      .update({ comments })
      .then(() => setSaving(false));
  };

  const sidebarContent = () => {
    if (selectedDemand === null || typeof demands === "undefined") {
      return null;
    }
    const demand = demands[selectedDemand];
    const {
      category,
      demandExternalReference,
      issuedAt,
      paidAmount,
      totalAmount,
      linkedAt,
      activeDueDate,
      status,
    } = demand.data;
    return (
      <Fragment>
        <FlexBox className="column">
          <Row className="">
            <SidebarTitle>{getDemandCategory(category)}</SidebarTitle>
          </Row>
          <Row className="">
            <Table>
              <tbody>
                <tr>
                  <td>Factuurnr:</td>
                  <td className="alignRight">{demandExternalReference}</td>
                </tr>
                <tr className="small">
                  <td className="xs">Factuurdatum:</td>
                  <td className="alignRight xs">
                    {new Date(issuedAt).toLocaleDateString("nl-NL")}
                  </td>
                </tr>
                <tr className="small">
                  <td className="xs">Status:</td>
                  <td className="alignRight xs">{status}</td>
                </tr>
                {linkedAt && (
                  <tr className="small">
                    <td className="xs">Gekoppeld met Seev:</td>
                    <td className="alignRight xs">
                      {linkedAt.toDate().toLocaleDateString("nl-NL")}
                    </td>
                  </tr>
                )}
                {!linkedAt && (
                  <tr className="small">
                    <td className="xs">Nog niet gekoppeld aan Seev</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Row>
        </FlexBox>
        <Line noMargin></Line>
        <Row>
          <Table className="bold">
            <tbody>
              <tr>
                <td className="l">Factuurbedrag:</td>
                <td className="l alignRight">€{totalAmount}</td>
              </tr>
              <tr
                className="small"
                style={{
                  fontWeight: "normal",
                  marginBottom: 10,
                }}
              >
                <td className="xs">Deadline betaalplan:</td>
                <td className="alignRight xs">
                  {new Date(activeDueDate).toLocaleDateString("nl-NL")}
                </td>
              </tr>
              {demand.data.paymentPlan && (
                <tr
                  className="small"
                  style={{
                    fontWeight: "normal",
                    marginBottom: 10,
                  }}
                >
                  <td className="xs">Nu te betalen:</td>
                  {demand.data.paymentPlan &&
                    demand.data.paymentPlan.dueAmount && (
                      <td className="alignRight xs">
                        €{roundString(demand.data.paymentPlan.dueAmount)}
                      </td>
                    )}
                </tr>
              )}
              <tr></tr>
              <tr>
                <td className="l">Saldo betaald:</td>
                <td className="l alignRight">€{roundNumber(paidAmount)}</td>
              </tr>
              {/* <tr>
                <td className="l">Saldo gestorneerd:</td>
                <td className="l alignRight">€</td>
              </tr> */}
            </tbody>
          </Table>
        </Row>
        <Line noMargin></Line>
        <Row>
          {!paymentsLoaded && <Dots />}
          {paymentsLoaded && (
            <Table>
              <tbody>
                {payments?.map((payment, i) => {
                  const { paymentProvider, status, hasChargebacks } =
                    payment.data;
                  const createdAt =
                    paymentProvider === "mollie"
                      ? payment.data.createdAt
                      : payment.data.created * 1000;
                  const paymentMonth = new Date(createdAt).getMonth();
                  const previousPayment = payments[i - 1];
                  const previousPaymentCreatedAt = previousPayment
                    ? previousPayment.data.paymentProvider === "mollie"
                      ? previousPayment.data.createdAt
                      : previousPayment.data.created
                    : 0;
                  const showMonth =
                    i === 0 || previousPaymentCreatedAt
                      ? new Date(previousPaymentCreatedAt).getMonth() !==
                        paymentMonth
                      : false;
                  const amount =
                    paymentProvider === "mollie"
                      ? payment.data.amount.value
                      : payment.data.amount / 100;
                  return (
                    <tr key={`payment-${payment.id}`}>
                      <td className="sm">
                        {showMonth && MONTHS[paymentMonth]}
                      </td>
                      <td className="sm">
                        €{paymentProvider === "mollie" ? amount : amount}
                      </td>
                      <td className="xxs alignCenter">
                        <Label>{hasChargebacks ? "gestorneerd" : status}</Label>
                      </td>
                      <td className="xxs alignRight">
                        {new Date(createdAt).toLocaleDateString("nl-NL")}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </Row>
        {demand.data.logs && (
          <Fragment>
            <div
              css={css`
                margin-top: 64px;
              `}
            >
              {Object.keys(demand.data.logs).map((key) => (
                <div
                  key={key}
                  css={css`
                    margin-bottom: 32px;
                  `}
                >
                  <div
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    {demand.data
                      .logs![key].createdAt.toDate()
                      .toLocaleString("nl-NL")}
                  </div>
                  <div
                    css={css`
                      margin-left: 16px;
                    `}
                  >
                    {demand.data.logs![key].message}
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        )}
        <div>
          <div>
            <a href={demand.data.url} target="_blank" rel="noreferrer">
              Betalen
            </a>
          </div>
        </div>
        <div>
          <div
            css={css`
              text-decoration: underline;
              cursor: pointer;
            `}
            onClick={() => setShowExtraInfoSidebar(!showExtraInfoSidebar)}
          >
            extra info
          </div>
        </div>
        {showExtraInfoSidebar && (
          <div>
            <div>
              <ReactJson src={demand} />
            </div>
          </div>
        )}
        {environment.FIREBASE_PROJECTID === "monnie-experimental" && (
          <Row>
            <div>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(parseFloat(e.target.value))}
              />
              <input
                type="text"
                value={customerId}
                onChange={(e) => setCustomerId(e.target.value)}
              />
              <select
                value={sequenceType}
                onChange={(e) => setSequenceType(e.target.value)}
              >
                <option value="oneoff">oneoff</option>
                <option value="recurring">recurring</option>
                <option value="first">first</option>
              </select>
              <select
                value={method}
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="ideal">ideal</option>
                <option value="directdebit">directdebit</option>
                <option value="creditcard">creditcard</option>
              </select>

              <button
                onClick={() => {
                  axios
                    .post(
                      `https://europe-west1-${environment.FIREBASE_PROJECTID}.cloudfunctions.net/createWebPayment`,
                      {},
                      {
                        data: {
                          demandId: demand.id,
                          redirectUrl: `${window.location.protocol}//${window.location.host}/admin/debitors`,
                          amount,
                          sequenceType,
                          method,
                          customerId,
                        },
                      }
                    )
                    .then((response) => {
                      const { paymentUrl } = response.data;
                      window.location = paymentUrl;
                    })
                    .catch((e) => {
                      setError(e.response.data.detail);
                    });
                }}
              >
                Betalen
              </button>
            </div>
          </Row>
        )}
      </Fragment>
    );
  };

  function getContent() {
    if (!loaded) {
      return <Dots />;
    }
    if (error) {
      return error;
    }
    if (!debitor) {
      return null;
    }
    const { firstName, lastName, externalReference } = debitor.data;
    return (
      <Fragment>
        <Row style={{ marginBottom: 44 }}>
          {/* <UserIconImage src={iconUser} /> */}
          <div style={{ paddingTop: 2 }}>
            <StyledH1>
              {firstName} {lastName}
            </StyledH1>
            <div>
              <UserData>Debiteurnr: {externalReference}</UserData>
            </div>
          </div>
        </Row>
        {demands?.map((demand, i) => {
          const {
            category,
            demandExternalReference,
            paidAmount,
            totalAmount,
            isLinkedToUser,
            status,
          } = demand.data;
          return (
            <div key={`demand-${demand.id}`}>
              <ListItem
                className={classNames({ selected: selectedDemand === i })}
                onClick={() => setSelectedDemand(i)}
              >
                <Row className="j-space-between margin-bottom">
                  <ListItemDataContainer>
                    <ListItemTitle>{getDemandCategory(category)}</ListItemTitle>
                  </ListItemDataContainer>
                  {status === "warning" && (
                    <div
                      css={css`
                        background: red;
                        color: white;
                        padding: 2px 8px;
                        border-radius: 4px;
                      `}
                    >
                      warning
                    </div>
                  )}
                  <ListItemDataContainer>
                    <ListItemData>
                      Factuurnr: {demandExternalReference}
                    </ListItemData>
                  </ListItemDataContainer>
                </Row>
                <ProgressBar>
                  {isLinkedToUser && (
                    <ProgressBarFill
                      style={{
                        width: `${
                          (100 * roundNumber(paidAmount)) / totalAmount
                        }%`,
                      }}
                    >
                      Betaald: €{roundNumber(paidAmount)}
                    </ProgressBarFill>
                  )}
                  <span style={{ zIndex: 2 }}>
                    Factuurbedrag: €{totalAmount}
                  </span>
                  {!isLinkedToUser && <span>Nog niet gekoppeld aan Seev</span>}
                </ProgressBar>
              </ListItem>
              <Line></Line>
            </div>
          );
        })}
        <div>
          <h3>opmerkingen</h3>
          <textarea
            css={css`
              width: 100%;
              padding: 16px;
            `}
            rows={5}
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
          <button disabled={saving} onClick={() => saveComments()}>
            opmerkingen oplaan
          </button>
        </div>
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={() => setShowExtraInfo(!showExtraInfo)}
        >
          {showExtraInfo ? "Verberg" : "Toon"} details
        </div>
        {showExtraInfo && (
          <div
            css={css`
              background: #ffffff;
            `}
          >
            <ReactJson src={debitor} />
          </div>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MainContainer
        backLink="/admin/debitors"
        sidebarContent={sidebarContent()}
      >
        {getContent()}
      </MainContainer>
    </Fragment>
  );
};
