import dotenv from "dotenv";
dotenv.config();

const {
  REACT_APP_FIREBASE_APIKEY,
  REACT_APP_FIREBASE_AUTHDOMAIN,
  REACT_APP_FIREBASE_DATABASEURL,
  REACT_APP_FIREBASE_PROJECTID,
  REACT_APP_FIREBASE_STORAGEBUCKET,
  REACT_APP_FIREBASE_MESSAGINGSENDERID,
  REACT_APP_FIREBASE_APPID,
} = process.env;

export const environment = {
  FIREBASE_APIKEY: REACT_APP_FIREBASE_APIKEY,
  FIREBASE_AUTHDOMAIN: REACT_APP_FIREBASE_AUTHDOMAIN,
  FIREBASE_DATABASEURL: REACT_APP_FIREBASE_DATABASEURL,
  FIREBASE_PROJECTID: REACT_APP_FIREBASE_PROJECTID,
  FIREBASE_STORAGEBUCKET: REACT_APP_FIREBASE_STORAGEBUCKET,
  FIREBASE_MESSAGINGSENDERID: REACT_APP_FIREBASE_MESSAGINGSENDERID,
  FIREBASE_APPID: REACT_APP_FIREBASE_APPID,
};
