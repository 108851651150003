import styled from "@emotion/styled";
import { mobile } from "../utils";

export const Button = styled.div`
  min-height: 75px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 12px 22px;
  cursor: pointer;
  font-family: Byrd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
`;

export const AButton = styled.a`
  display: block;
  min-height: 75px;
  border-radius: 20px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 12px 22px;
  cursor: pointer;
  text-decoration: none;
  font-family: Byrd;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
`;

export const RoundButton = styled.div`
  min-height: 75px;
  background-color: #000;
  color: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 12px 40px;
  cursor: pointer;
  border-radius: 37px;
  font-family: Byrd;
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0px;
  text-align: center;
  width: 430px;
  ${mobile} {
    width: 100%;
    font-family: Byrd;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0px;
    text-align: center;
    padding: 0px 30px;
    min-height: 54px;
  }
`;

export const ImgButton = styled.img`
  cursor: pointer;
  width: 26px;
  padding: 12px;
  box-sizing: content-box;
`;
