import React from "react";
import { Route } from "react-router-dom";
import { NavBar } from "../components/nav-bar";
import { DebitorDetail } from "./admin/debitor-detail";
import { DebitorList } from "./admin/debitor-list";

export const Development = () => {
  return (
    <>
      <NavBar>
        <h1>Seev crediteurendashboard</h1>
        <button>logout</button>
      </NavBar>
      <Route path="/dev/debitors" component={DebitorList} />
      <Route path="/dev/debitors/:debitorId" component={DebitorDetail} />
    </>
  );
};
