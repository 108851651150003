/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { H1, H2, MainContainer } from "../components";

const Thanks = () => {
  return (
    <MainContainer>
      <H1
        css={css`
          color: #fff;
        `}
      >
        Betaald!
      </H1>
      <H2
        css={css`
          color: #fff;
        `}
      >
        Bedankt voor de betaling. Je kan dit venster sluiten.
      </H2>
    </MainContainer>
  );
};

export default Thanks;
