import idealImport from "./ideal.png";
import monnieLogoSmallImport from "./SEEV_CMYK_LOGO.png";
import monnieLogoLargeImport from "./SEEV_CMYK_LOGO.png";
import logoBlackImport from "./Logo.png";
import caretRightImport from "./caret-right.png";
import caretRightGreyImport from "./caret-right-grey.png";
import iconUserImport from "./icon-user.png";
import arrowBackImport from "./arrow-back.png";
import searchIconImport from "./search-icon.png";
import appInHandImport from "./app-in-hand.png";
import phone3dImport from "./phone-3d.png";
import questionsDesktopImport from "./questions-desktop.png";
import questionsMobileImport from "./questions-mobile.png";
import checkImport from "./check.png";
import peopleImport from "./people.png";

export const ideal = idealImport;
export const monnieLogoSmall = monnieLogoSmallImport;
export const monnieLogoLarge = monnieLogoLargeImport;
export const caretRight = caretRightImport;
export const caretRightGrey = caretRightGreyImport;
export const iconUser = iconUserImport;
export const arrowBack = arrowBackImport;
export const searchIcon = searchIconImport;
export const appInHand = appInHandImport;
export const phone3d = phone3dImport;
export const questionsDesktop = questionsDesktopImport;
export const questionsMobile = questionsMobileImport;
export const logoBlack = logoBlackImport;
export const check = checkImport;
export const people = peopleImport;
