import styled from "@emotion/styled";
import axios from "axios";
import { css } from "emotion";
import React, { useContext, useEffect, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";
import { useHistory, useParams } from "react-router-dom";
import { caretRight, ideal, monnieLogoSmall } from "../assets/images";
import { AButton, Button, H1, H2, MainContainer, Section } from "../components";
import { environment } from "../modules/config";
import { AppContext } from "../modules/core";
import { Demand, DemandStatus } from "../shared";
import { formatDemandCategory, getDocument } from "../utils";

const StyledH1 = styled(H1)`
  color: #ffffff;
`;

const TableLabel = styled.td`
  height: 32px;
  font-family: Byrd;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  padding-right: 60px;
`;

const TableValue = styled.td`
  height: 32px;
  font-family: Byrd;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
`;

const OptionContainer = styled.div`
  margin-bottom: 34px;
`;

const OptionTitle = styled.div`
  color: #373737;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 11px;
`;

const Disclaimer = styled.div`
  /* color: #9b9b9b; */
  font-size: 12px;
`;

const ErrorContainer = styled.div`
  background-color: #ff0000;
  color: white;
  padding: 16px;
  font-weight: bold;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Overlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const AppMenu = styled.div`
  background: #eee;
  padding: 64px;
  border-radius: 8px;

  @media screen and (max-width: 640px) {
    padding: 16px;
  }
`;

interface ParamTypes {
  demandId: string;
}

const DemandPage = () => {
  const { demandId } = useParams<ParamTypes>();
  const { firebase } = useContext(AppContext);
  const history = useHistory();
  const deeplinkUrl = `monnie://--/linkDemand?demandId=${demandId}`;
  const deeplinkUrlDev = `exp://192.168.1.234:19000/--/linkDemand?demandId=${demandId}`;
  const encodedDeeplinkUrl = encodeURIComponent(deeplinkUrl);
  const redirectUrl = `${window.location.protocol}//${window.location.host}/demand/${demandId}/payment/thanks`;
  const [demand, setDemand] = useState<FirestoreDocument<Demand>>();
  const [loaded, setLoaded] = useState<boolean>();
  const [error, setError] = useState();
  const [idealLoading, setIdealLoading] = useState(false);
  const [appMenuOpen, setAppMenuOpen] = useState(false);

  useEffect(() => {
    const getDemand = async () => {
      if (!firebase.db) {
        return;
      }
      try {
        const demand = await getDocument<Demand>(
          firebase.db,
          "demands",
          demandId
        );
        setLoaded(true);
        setDemand(demand);
      } catch (e: any) {
        setError(e.message);
        setLoaded(true);
      }
    };
    getDemand();
  }, [demandId, firebase.db]);
  return (
    <MainContainer moveUp maxWidth="600px">
      <StyledH1>Betaalverzoek</StyledH1>

      {error && <ErrorContainer>{error}</ErrorContainer>}
      {getContent()}
    </MainContainer>
  );
  function getContent() {
    if (!loaded) {
      return (
        <div>
          <Dots />
        </div>
      );
    }
    if (demand) {
      const demandData = demand.data;
      if (demandData.status === DemandStatus.Corrupt || demandData.isBlocked) {
        return (
          <Section>
            <p>
              <strong>Niet beschikbaar</strong>
            </p>
            <p>
              Deze betaallink is niet beschikbaar. Voor vragen, neem contact op
              met <a href="mailto:support@seevapp.nl">support@seevapp.nl</a>
            </p>
          </Section>
        );
      }
      return (
        <>
          <Section>
            <table>
              <tbody>
                <tr>
                  <TableLabel>Omschrijving:</TableLabel>
                  <TableValue>
                    {formatDemandCategory(demandData.category)}
                  </TableValue>
                </tr>
                <tr>
                  <TableLabel>Factuurnummer:</TableLabel>
                  <TableValue>{demandData.demandExternalReference}</TableValue>
                </tr>
                <tr>
                  <TableLabel>Factuurdatum:</TableLabel>
                  <TableValue>
                    {new Date(demandData.issuedAt).toLocaleDateString("nl")}
                  </TableValue>
                </tr>
                <tr>
                  <TableLabel>Factuurbedrag:</TableLabel>
                  <TableValue>
                    €{amountToString(demandData.totalAmount)}
                  </TableValue>
                </tr>
                <tr>
                  <TableLabel>Openstaand:</TableLabel>
                  <TableValue>
                    €
                    {amountToString(
                      demandData.totalAmount - demandData.paidAmount
                    )}
                  </TableValue>
                </tr>
              </tbody>
            </table>
          </Section>
          <Section>
            <StyledH1>Kies hoe je wilt betalen</StyledH1>
            <OptionContainer>
              <AButton
                // href={`https://europe-west1-${environment.FIREBASE_PROJECTID}.cloudfunctions.net/deeplink?url=${encodedDeeplinkUrl}`}
                onClick={() => history.push(`/create-account/${demandId}`)}
              >
                <div
                  className={css`
                    width: 58px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  <img alt="" style={{ width: "100%" }} src={monnieLogoSmall} />
                </div>
                <div
                  className={css`
                    margin-left: 28px;
                    flex: 1;
                  `}
                >
                  Betaal in delen
                </div>
                <img alt="" src={caretRight} />
              </AButton>
            </OptionContainer>

            <OptionContainer>
              <Button
                onClick={() => {
                  setIdealLoading(true);
                  axios
                    .post(
                      `https://europe-west1-${environment.FIREBASE_PROJECTID}.cloudfunctions.net/createWebPayment`,
                      {},
                      {
                        data: {
                          demandId,
                          redirectUrl,
                        },
                      }
                    )
                    .then((response) => {
                      const { paymentUrl } = response.data;
                      window.location = paymentUrl;
                    })
                    .catch((e) => {
                      setError(e.response.data.detail);
                    })
                    .finally(() => {
                      setIdealLoading(false);
                    });
                }}
              >
                <div
                  className={css`
                    width: 58px;
                    display: flex;
                    justify-content: center;
                  `}
                >
                  {idealLoading ? (
                    <Dots />
                  ) : (
                    <img
                      className={css`
                        width: 100%;
                      `}
                      alt=""
                      src={ideal}
                    />
                  )}
                </div>
                <div
                  className={css`
                    margin-left: 28px;
                    flex: 1;
                  `}
                >
                  Alles in een keer betalen
                </div>

                <img alt="" src={caretRight} />
              </Button>
            </OptionContainer>
            <Disclaimer>
              Seev communiceert duidelijk en gemakkelijk over betalingen en
              afspraken. Stel via de app een betaalplan in. Seev biedt je dan
              een duidelijk overzicht van het totaal, herinnert je elke maand
              aan je betaling en laat je zien wat je al hebt bereikt!{" "}
              <a href="https://www.Seevapp.nl/voor-jongeren">Meer over Seev.</a>
            </Disclaimer>
          </Section>
          {appMenuOpen && (
            <OverlayContainer>
              <Overlay onClick={() => setAppMenuOpen(false)} />
              <AppMenu>
                <Section>
                  <div style={{ textAlign: "center" }}>
                    <img
                      alt=""
                      style={{ maxWidth: 200, marginBottom: 32 }}
                      src={monnieLogoSmall}
                    />
                  </div>
                  <OptionContainer>
                    <OptionTitle>Heb je de app nog niet?</OptionTitle>
                    <AButton
                      href={`https://europe-west1-${environment.FIREBASE_PROJECTID}.cloudfunctions.net/deeplink?url=${encodedDeeplinkUrl}`}
                    >
                      <H2 style={{ marginRight: 32, textDecoration: "none" }}>
                        Download de app
                      </H2>

                      <img alt="" src={caretRight} />
                    </AButton>
                  </OptionContainer>
                  <OptionContainer>
                    <OptionTitle>Heb je de app al?</OptionTitle>
                    <AButton href={deeplinkUrl}>
                      <H2>Naar Seev</H2>
                      <img alt="" src={caretRight} />
                    </AButton>
                  </OptionContainer>
                  {environment.FIREBASE_PROJECTID === "monnie-experimental" && (
                    <OptionContainer>
                      <OptionTitle>Development</OptionTitle>
                      <AButton href={deeplinkUrlDev}>
                        <H2>Open in Expo</H2>
                        <img alt="" src={caretRight} />
                      </AButton>
                    </OptionContainer>
                  )}
                </Section>
              </AppMenu>
            </OverlayContainer>
          )}
        </>
      );
    }
  }
};

function amountToString(amount: number) {
  const amountEuro = Math.floor(amount);
  const amountCents = Math.floor((amount - amountEuro) * 100);
  return `${amountEuro},${String(amountCents).padStart(2, "0")}`;
}

export default DemandPage;
