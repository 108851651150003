export function formatDemandCategory(category: string): string {
  switch (category) {
    case "studyBooks":
      return "Schoolkosten";
    default:
      return category;
  }
}

export function downloadString(
  text: string,
  fileType: string,
  fileName: string
) {
  const blob = new Blob([text], { type: fileType });
  const a = document.createElement("a");
  a.download = fileName;
  a.href = URL.createObjectURL(blob);
  a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 1500);
}
