import React, { FC, useContext, useEffect, useState } from "react";
import { Dots } from "react-activity";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../modules/core";
import { UserContext } from "../modules/user";
import { User } from "../shared";
import { Container } from "./container";

type PrivateRouteProps = {
  path: string;
};
export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { firebase } = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState<boolean>();
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  useEffect(() => {
    const unsubscribe = firebase.app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await firebase.db
          .collection("users")
          .doc(await user.uid)
          .get();
        if (userDoc.exists) {
          setUser(userDoc.data() as User);
          setUserId(user.uid);
          if (
            userDoc.data()!.role === "admin" ||
            userDoc.data()!.role === "staff"
          ) {
            setLoggedIn(true);
            setReady(true);
            return;
          }
        }
      }
      setUserId(null);
      setUser(null);
      setLoggedIn(false);
      setReady(true);
    });
    return () => unsubscribe();
  });
  return (
    <UserContext.Provider value={{ user, id: userId }}>
      <Route
        {...rest}
        render={() =>
          ready ? (
            loggedIn ? (
              children
            ) : (
              <Redirect to="/login" />
            )
          ) : (
            <Container>
              <Dots />
            </Container>
          )
        }
      />
    </UserContext.Provider>
  );
};
