import React, { ChangeEvent, FunctionComponent } from "react";
import styled from "@emotion/styled";
import { searchIcon } from "../assets/images";

const Container = styled.div`
  position: relative;
  flex: 1;
  margin-top: 8px;
`;

const StyledSearchBar = styled.input`
  height: 55px;
  width: 100%;
  border-radius: 5px;
  background-color: #dddddd;
  margin-bottom: 14px;
  margin-top: 4px;
  border: none;
  outline: none;
  padding: 16px;
  color: #9b9b9b;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
`;

const SearchIconImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;
`;

type SearchBarProps = {
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onSubmit?: () => void;
  value?: string;
};

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  placeholder,
  onChange,
  onSubmit,
  value,
}) => {
  return (
    <Container>
      <StyledSearchBar
        onChange={(e) => onChange && onChange(e)}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e) => e.key === "Enter" && onSubmit && onSubmit()}
      ></StyledSearchBar>
      <SearchIconImg onClick={onSubmit} src={searchIcon}></SearchIconImg>
    </Container>
  );
};
