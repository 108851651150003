import styled from "@emotion/styled";
import { FlexBox } from "./flex-box";

export const Layout = styled(FlexBox)`
  position: absolute;
  top: 73px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 18px;
  background: #f9fafb;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
