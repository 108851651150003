import { css } from "emotion";
import React, {
  FunctionComponent,
  ReactElement,
  useRef,
  useState,
} from "react";
import { FaChevronDown } from "react-icons/fa";
import { Dropdown } from "./Dropdown";
import { styleChevron } from "./styleChevron";

const ChevronDown = styleChevron(FaChevronDown);
export type PopoutMenuItem = { name: string; value: number };
type PopoutMenuProps = {
  items: PopoutMenuItem[];
  title: ReactElement;
  onMenuItemClick?: (item: PopoutMenuItem, i: number) => void;
};
export const PopoutMenu: FunctionComponent<PopoutMenuProps> = ({
  items,
  title,
  onMenuItemClick,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const handleMenuItemClick = (item: PopoutMenuItem, i: number) => {
    if (dropdownRef && dropdownRef.current) {
      dropdownRef.current.blur();
    }
    if (onMenuItemClick) {
      onMenuItemClick(item, i);
    }
  };
  return (
    <Dropdown
      {...props}
      onFocus={() => setOpen(true)}
      tabIndex={0}
      onBlur={() => setOpen(false)}
      ref={dropdownRef}
    >
      <div>
        {title} <ChevronDown />
      </div>
      {open && (
        <div
          className={css`
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            border-radius: 5px;
            background-color: #ffffff;
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 1;
          `}
        >
          {items.map((item, i) => (
            <div
              className={css`
                padding: 9px 13px;
                width: 150px;
                cursor: pointer;
                border-bottom: 1px solid #eee;
                &:hover {
                  background: #eee;
                }
              `}
              key={`filter-item-${i}`}
              onClick={() => handleMenuItemClick(item, i)}
            >
              {item.name}
            </div>
          ))}
        </div>
      )}
    </Dropdown>
  );
};
