import styled from "@emotion/styled";
import { mobile } from "../utils";

export const H1 = styled.h1`
  //styleName: H1;
  font-family: Byrd;
  font-size: 55px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px;
  letter-spacing: 0px;
  text-align: left;

  ${mobile} {
    font-family: Byrd;
    font-size: 35px;
    font-style: normal;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
  }
`;
export const H2 = styled.h2`
  //styleName: H2 - regular;
  font-family: Byrd;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  ${mobile} {
    font-family: Byrd;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 30px;
  }
`;
