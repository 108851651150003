/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled-base";
import React from "react";
import { H1 } from ".";
import { Column, Row } from "./flex-box";

const StyledH1 = styled(H1)`
  color: #fff;
`;

export const Footer = () => (
  <Row
    css={css`
      background: #000;
      padding: 100px 30px;
      margin-bottom: 0;
    `}
  >
    <Column
      css={css`
        align-items: center !important;
      `}
    >
      <StyledH1>Hulp nodig?</StyledH1>
    </Column>
    <Column
      css={css`
        align-items: center !important;
      `}
    >
      <a
        css={css`
          font-family: Byrd;
          font-size: 25px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0px;
          text-align: right;
          color: white;
        `}
        href="mailto:support@seevapp.nl"
      >
        support@seevapp.nl
      </a>
    </Column>
  </Row>
);
