/**
 * This file contains all database models in one place so that it can be easily
 * synced with the mobile repo.
 *
 * @TODO Ideally these types would be part of a separate shared code repository,
 * or solve this via a monorepo setup.
 */

/**
 * Maintenance is checked in the client app to see if we need to block the users
 * from accessing the database. This is useful for running patches and other
 * scripts on the data while blocking all user operations.
 */
export interface Maintenance {
  isActive: boolean;
  message?: string;
}

// collection /users/{userId}
export interface User {
  firstName?: string;
  lastName?: string;

  createdAt: FirestoreTimestamp;
  // used for firestore rules
  role: "user" | "admin" | "staff"; // staff is currently not used

  /**
   * admins can read documents if they are linked to at least one of their creditors.
   */
  creditors?: { id: string; name: string }[]; // set manually through firestore gui
  creditorIds?: string[]; // set automatically by trigger

  /**
   * Depending on method of sign-up with have either one.
   */
  email?: string;
  phoneNumber?: string; // phone sign-up not implemented yet

  /**
   * DEPRECATED @TODO: remove all Mollie functionality
   * When the user is first created, a mollie customer is created too. This is
   * required for recurring payments.
   */
  mollieCustomerId?: string;

  /**
   * Mandates are used for recurring payments. Once a mandate has been created
   * we can reuse it on-demand, so only one mandate per user is required.
   * We store a mandate per payment provider to maintain compatibility. Eventually
   * we can stop supporting Mollie.
   */
  hasMandate: {
    mollie: boolean;
    opp: boolean;
  };
  mandateId: {
    mollie?: string;
    opp?: string;
  };
}

/**
 * The company that issues the demand e.g Albeda.
 *
 * If multiple divisions in the same company issue different demands multiple
 * creditors could be created for the same name. Each will have a unique id
 * which is linked to the demand document.
 * Creditors are created manually through firestore gui.
 */
export interface Creditor {
  name: string;
  // logo: Base64String;
  address?: string;
  postCode?: string;
  country?: string;
  phoneNumber?: string;
  email?: string;
  username: string;
  oppMerchantId: string; // obtain this id through opp merchant onboarding
  // see https://guides.onlinepaymentplatform.com/#merchant-onboarding
}

/**
 * collection /debitors/{debitorId}
 * A debitor is automatically created through a trigger when a new Demand is created.
 * Debitor id is constructed as {creditorId}-{demand.debitorExternalReference}
 */
export interface Debitor {
  firstName?: string;
  lastName?: string;
  creditorId: string;
  externalReference: string;
  isCompleted?: boolean;
  hasWildcard?: boolean;
  hasUnplannedDemand?: boolean;
  hasDuePayment?: boolean;
  hasWarning?: boolean;
  demands?: (Demand & { id: string })[];
  log?: { message: string; loggedAt: FirestoreTimestamp }[];
  note?: string;
  pilot1?: boolean;
  pilot2?: boolean;
  hasLinkedAccount?: boolean;
  hasPlannedDemand?: boolean;
  hasPaidThroughApp?: boolean;
  hasPaidThroughWeb?: boolean;
  comments?: string;
  isFaircasso?: boolean; // indicates whether Debitor is now handled by faircasso. Is true if at least one of Debitor's Demands has isFaircasso=true
}

/**
 *
 */
export interface Demand {
  issuedAt: IsoDateString; // Date letter was send out

  creditorId: string; // Reference to the Creditor document
  creditorName?: string;
  debitorId?: string; // Reference to the Debitor document

  totalAmount: number;

  firstName?: string;
  lastName?: string;

  /**
   * Maybe we want to store a reference to the Debitor from the Creditor's
   * administration, so that when a problem arises we can reference the user by
   * this code when contacting the Creditor.
   */
  debitorExternalReference: string;

  /**
   * The Creditor probably has a reference in their system to the Demand. We
   * could include this with the transactions made to the Creditor.
   */
  demandExternalReference: string;

  /**
   * The original due date when the demand was first created.
   */
  originalDueDate: IsoDateString;

  /**
   * Possibly we need to be able to change the due date once a User can not make
   * the deadline and the Creditor agrees to extend it?
   */
  activeDueDate: IsoDateString;

  category: DemandCategoryType; // See enum below

  /**
   * Once the first user has scanned the QR code. The demand document will be
   * linked to that specific user. By recording the boolean we can easily query
   * which demands haven't been linked yet. Also these fields can be used to
   * prevent another user from initiating a payment plan on a demand which was
   * already linked to another user.
   */
  isLinkedToUser: boolean;
  linkedUserId?: string;
  linkedAt?: FirestoreTimestamp;

  /**
   * Keep track of now much has been paid, and flag a demand as completed when
   * all payments have been made.
   *
   * @TODO possibly easier to store this data in the payment plan instead?
   */
  paidAmount: number;
  paidAmountThroughWeb?: number;
  paidAmountThroughApp?: number;
  isCompleted: boolean;
  completedAt?: FirestoreTimestamp;

  /**
   * Internal timestamps to keep track of when the document was created and last
   * updated.
   */
  createdAt: FirestoreTimestamp;
  modifiedAt?: FirestoreTimestamp;

  status: DemandStatus;
  administration?: number;
  description?: string;

  hasPaymentPlan: boolean;
  paymentPlan?: PaymentPlan & { id: string };
  fromExact?: boolean;
  mails?: { template: string; sentAt: FirestoreTimestamp; to: string }[];
  phone?: string;
  url?: string;
  pilot1?: boolean;
  pilot2?: boolean;
  logs?: { [id: string]: Log };
  latestLogMessage?: string;
  paidDirectly?: boolean;
  isFaircasso?: boolean; // indicates whether demand is now handled by Faircasso. set to true manually or through migration
  isExcludedFromMailing: boolean; // indicates whether we should not send mails relating to this demand
  isReplacement?: boolean; // used for fault correction. If this demand has been created to replace another.
  originalId?: string; // used for fault correction. If this demand has been created to replace another, this is the id of the replaced demand.
  isBlocked?: boolean; // used to block demand web page from showing payment link.
}

/**
 * Keep track of the status of a demand. When a user fails to pay we can flag
 * this demand to provide feedback or trigger other actions.
 */
export enum DemandStatus {
  Ok = "ok",
  Warning = "warning",
  Critical = "critical",
  Failed = "failed",
  Corrupt = "corrupt",
}

/**
 * In the demand / payment plan overview, there is an illustration based on the
 * category. Others can be added later.
 */
export enum DemandCategoryType {
  StudyBooks = "studyBooks",
}

export interface PaymentPlan {
  createdBy: string; // Reference to user who made this plan
  createdAt: FirestoreTimestamp;
  demandId: string;

  /**
   * The amount that is payed monthly. When a payment fails, and the user
   * changes the monthly amount, the history of all payments can still be found
   * in the Payments sub-collection.
   *
   */
  monthlyPaymentAmount: number;

  transferType: TransferType;

  /**
   * The user can use a wildcard once to skip a payment. Using the wildcard on
   * manual payments would be fairly easy, but for periodic payments we would
   * probaby have to cancel and reschedule them.
   */
  isWildcardUsed: boolean;
  wildcardUsedAt?: FirestoreTimestamp;

  /**
   * We store the timestamp the last notification was sent per notification
   * type. We use this to prevent our payment-reminder function from sending
   * double reminders.
   */
  lastNotifications: {
    threeDaysSentAt?: FirestoreTimestamp;
    sameDaySentAt?: FirestoreTimestamp;
  };

  /**
   * We store some information when monthlyPaymentAmount changes in order to
   * calculate the dueAmount.
   */
  savedWhenMonthlyPaymentAmountChanged?: {
    changedAt: FirestoreTimestamp;
    paidAmount: number;
    dueAmount: number;
  };

  /**
   * The payment amount that is due right now, based on paycheckDay,
   * monthlyPaymentAmount the current date and Demand.totalAmount
   */
  dueAmount?: number;
  dueAmountSetAt?: FirestoreTimestamp;
  isPaymentDue?: boolean;
  paymentDueSince?: FirestoreTimestamp;
  paymentProvider: "mollie" | "opp";
}

export enum TransferType {
  Oneoff = "oneoff",
  Recurring = "recurring",
}

export type MolliePayment = {
  mode: "test" | "live";
  paymentProvider: "mollie";
  status: PaymentStatus;
  description: string;
  method: "ideal" | "directdebit" | "creditcard";
  metadata: {
    userId?: string;
    demandId: string;
    creditorId: string;
    settled: boolean;
    settlementId?: string;
    webPayment?: boolean;
    debitorExternalReference: string;
    demandExternalReference: string;
  };
  profileId: string;
  sequenceType: PaymentSequenceType;
  mandateId?: string;
  amount: {
    currency: string;
    value: string;
  };
  applicationFee: {
    amount: {
      currency: string;
      value: string;
    };
    description: string;
  };
  details: {
    bankName?: string;
    bankAccount?: string;
    bankBic?: string;
    transferReference?: string;
    consumerName?: string;
    consumerAccount?: string;
    consumerBic?: string;
  };
  createdAt: IsoDateString;
  paidAt?: IsoDateString;
  failedAt?: IsoDateString;
  cancelledAt?: IsoDateString;
  expiredAt?: IsoDateString;
  hasChargebacks?: boolean;
};

type OPPPaymentStatus =
  | "created"
  | "pending"
  | "planned"
  | "completed"
  | "reserved"
  | "cancelled"
  | "failed"
  | "expired"
  | "refunded"
  | "chargeback";

export type OPPPayment = {
  paymentProvider: "opp";
  uid: string;
  object: "transaction";
  payment_method: "ideal" | "mandate";
  payment_details: {
    reference: string;
    code:
      | "AC01"
      | "AC04"
      | "AC06"
      | "AG02"
      | "AM04"
      | "MD01"
      | "MD06"
      | "MD07"
      | "MS02"
      | "MS03"
      | "SL01";
    message: string;
  } | null;
  created: number;
  updated: number;
  completed: number | null;
  merchant_uid: string;
  profile_uid: string;
  has_checkout: boolean;
  payment_flow: "direct" | "email";
  amount: number;
  return_url: string;
  redirect_url: string;
  notify_url: string;
  status: OPPPaymentStatus;
  statuses: {
    uid: string;
    object: "status";
    created: number;
    updated: number;
    status: OPPPaymentStatus;
  }[];
  metadata: { key: string; value: string }[];
  convertedMetadata: { [key: string]: string };
  order: any[];
  escrow: {
    uid: string;
    object: "escrow";
    period: number;
    start: number;
    end: number;
  }[];
  fees: {
    merchant_gateway_fee: number;
    merchant_transaction_fee: number;
    merchant_order_fee: number;
    merchant_payable_amount: number;
    gateway_fee: number;
    transaction_fee: number;
    order_fee: number;
    payable_amount: number;
  };
  refunds: {
    uid: string;
    object: "refund";
    created: number;
    updated: number;
    paid: number;
    amount: number;
    status: string;
    message: null | string;
    internal_reason: null | string;
    fees: any;
    payout_description: null | string;
  };
  hasChargebacks?: boolean;
  settled?: boolean;
  faulty?: boolean;
  correction?: {
    debitorExternalReference: string;
    demandExternalReference: string;
  };
};

export type Payment = MolliePayment | OPPPayment;

export type Chargeback = MollieChargeback | OPPChargeback;

export type MollieChargeback = {
  paymentProvider: "mollie";
  id: string;
  amount: {
    currency: string;
    value: string;
  };
  createdAt: IsoDateString;
  paymentId: string;
  settlementAmount: {
    currency: string;
    value: string;
  };
  settled?: boolean;
  creditorId?: string;
  demandId?: string;
  demandExternalReference?: string;
  debitorExternalReference?: string;
};

export type OPPChargeback = {
  paymentProvider: "opp";
  id: string;
  amount: number;
  createdAt: number;
  paymentId: string;
  settled?: boolean;
  creditorId?: string;
  demandId?: string;
  demandExternalReference?: string;
  debitorExternalReference?: string;
};

export type PaymentSequenceType = "oneoff" | "first" | "recurring";

export enum PaymentStatus {
  Paid = "paid",
  Expired = "expired",
  Failed = "failed",
  Cancelled = "cancelled",
  Pending = "pending",
}

export type NotificationTypeKey =
  | "threeDays"
  | "sameDay"
  | "paymentFailure"
  | "paymentSuccess";

export interface UserSettings {
  /**
   * We can store the IBAN after first time use for later reference. Same for
   * paycheck day. Not required for MVP but simple enough to add now.
   */
  paycheckDay?: number;
  // iban?: string; // we can't actually pass the iban with to the mollie api

  notificationTypes: {
    threeDays: boolean;
    sameDay: boolean;
    paymentSuccess: boolean;
  };
}

export interface UserSessions {
  [pushToken: string]: {
    pushToken: string;
  };
}

/**
 * A settlement is a Document containing information relating to a periodic
 * payment made to a creditor. The settlement payment contains all payments that
 * we have received through Mollie in the last period.
 */

export interface MollieSettlement {
  creditorId: string;
  createdAt: FirestoreTimestamp;
  amount: {
    currency: string;
    value: string;
  };
  payments: {
    [key: string]: {
      amount: { currency: string; value: string };
      creditorId: string;
      debitorExternalReference: string;
      demandExternalReference: string;
      demandId: string;
      settled: boolean;
      userId?: string;
    };
  };
  chargebacks: {
    [key: string]: Chargeback;
  };
}
export interface OPPSettlement {
  creditorId: string;
  createdAt: FirestoreTimestamp;
  amount: number;
  payments: {
    [key: string]: {
      [key: string]: { key: string; value: string };
    };
  };
}

export enum MandateStatus {
  Created = "created",
  Pending = "pending",
  Completed = "completed",
  Cancelled = "cancelled",
  Failed = "failed",
  Expired = "expired",
  Revoked = "revoked",
}

export interface Mandate {
  paymentProvider: "opp";
  uid: string;
  expired: number;
  amount: number;
  status: MandateStatus;
  metadata: { key: string; value: string }[];
  token: string;
  statuses: {
    uid: string;
    object: string;
    created: number;
    updated: number;
    status: MandateStatus;
  }[];
}

export interface Log {
  demandId: string;
  createdAt: FirestoreTimestamp;
  message: string;
}

export interface Mail {
  demandId: string;
  sentAt: FirestoreTimestamp;
  template: string;
  to: string;
}
