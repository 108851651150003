/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { User } from "firebase";
import { Fragment, useContext, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  appInHand,
  check,
  logoBlack,
  phone3d,
  questionsDesktop,
  questionsMobile,
} from "../assets/images";
import { H1, H2, MainContainer, RoundButton } from "../components";
import { Column, Row } from "../components/flex-box";
import { Footer } from "../components/Footer";
import { AppContext } from "../modules/core";
import { FieldValue } from "../modules/firebase";
import { Demand } from "../shared";
import { desktop, mobile } from "../utils";

const StyledH1 = styled(H1)`
  color: #ffffff;
`;

const StyledInput = styled.input`
  font-family: Byrd;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  height: 90px;
  width: 100%;
  background: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  border: none;
  padding: 30px 62px;
  margin-bottom: 24px;
  ${mobile} {
    height: 80px;
    font-family: Byrd;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    padding: 8px 22px;
  }
`;

const USP: React.FC = ({ children, ...props }) => (
  <div
    css={css`
      background: #ffffff;
      box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      height: 90px;
      padding: 0 40px;
      display: flex;
      align-items: center;
      //styleName: H3 - Medium;
      font-family: Byrd;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
      margin-bottom: 18px;
      ${mobile} {
        font-family: Byrd;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        text-align: left;
        height: 60px;
        border-radius: 19px;
      }
    `}
    {...props}
  >
    <img
      css={css`
        margin-right: 50px;
      `}
      src={check}
      alt=""
    />
    {children}
  </div>
);

export const CreateAccountPage = () => {
  const { firebase } = useContext(AppContext);
  const history = useHistory();
  const { demandId } = useParams<{ demandId: string }>();
  const [signupFormData, setSignupformData] = useState<{
    firstName?: string;
    lastName?: string;
    // phone?: string;
    email?: string;
    password?: string;
    privacy: boolean;
  }>({ privacy: false });
  const [signinFormData, setSigninFormData] = useState<{
    email?: string;
    password?: string;
  }>({});
  const [error, setError] = useState<string>();
  const [shownForm, setShownForm] = useState<"signup" | "signin">("signup");

  async function linkDemand(demandId: string | undefined | null, user: User) {
    if (!demandId) {
      throw new Error(`Tried to link demand without ID`);
    }
    /**
     * Verify if id is part of database
     */
    const ref = firebase.db.collection("demands").doc(demandId);
    const doc = await ref.get();

    if (!doc.exists) {
      throw new Error(`Failed to find demand with id ${demandId}`);
    }

    /**
     * Check if the demand was already linked to a user
     */
    const documentData = doc.data()! as Demand;
    if (documentData.isLinkedToUser) {
      throw new Error(
        `This document as already linked to a user at ${documentData
          // @ts-ignore @TODO figure out timestamp types
          .linkedAt!.toDate()
          .toLocaleDateString()}`
      );
    }
    /**
     * Link the demand to the user
     *
     * @TODO move to helper function
     */
    const userId = user.uid;
    const updateData: DocumentUpdate<Demand> = {
      isLinkedToUser: true,
      linkedUserId: userId,
      linkedAt: FieldValue.serverTimestamp(),
    };

    await ref.update(updateData);

    /*console.log(`Linked demand ${demandId} to user ${userId}`);*/

    /**
     * If name is set in the demand data, we use that to store / overwrite the
     * name data in the user object, since we currently have no other way to
     * know the users name.
     */
    const {
      firstName,
      lastName,
      // phone
    } = signupFormData;

    if (firstName) {
      await firebase.db
        .collection("users")
        .doc(userId)
        .set(
          {
            firstName: firstName ? firstName : documentData.firstName,
            lastName: lastName ? lastName : documentData.lastName,
            // phone,
          },
          { merge: true }
        );
    }
    return documentData;
  }

  const handleFormSubmit = () => {
    const { email, password } = signupFormData;
    if (!email || !password) {
      return;
    }
    firebase.app
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async ({ user }) => {
        if (!user) {
          throw new Error("no user");
        }
        await linkDemand(demandId, user);
        history.push("/account-created");
      })
      .catch((e) => setError(e.message));
  };
  return (
    <Fragment>
      <MainContainer
        moveUp
        background="linear-gradient(180deg, #5D7BFE 0%, #55D9B4 38.02%, #FFA6E5 71.35%)"
        staticContainer={true}
      >
        <Row
          css={css`
            padding-top: 60px;
          `}
        >
          <Column
            css={css`
              padding-right: 100px;
              ${mobile} {
                padding: 0;
                align-items: center;
              }
            `}
          >
            <img
              css={css`
                ${desktop} {
                  display: none;
                }
              `}
              src={logoBlack}
              alt=""
            />
            <img
              css={css`
                width: 100%;
                ${mobile} {
                  margin: 40px auto 0;
                }
              `}
              src={appInHand}
              alt=""
            />
          </Column>
          <Column
            css={css`
              ${mobile} {
                align-items: center !important;
                * {
                  text-align: center;
                }
              }
            `}
          >
            <img
              css={css`
                ${mobile} {
                  display: none;
                }
              `}
              src={logoBlack}
              alt=""
            />
            <StyledH1>Betaal je rekening zoals jij dat wilt</StyledH1>
            <H2>Zonder gedoe of extra kosten</H2>
            <a
              href="#sign-up"
              css={css`
                text-decoration: none;
              `}
            >
              <RoundButton>Ik wil met seev betalen</RoundButton>
            </a>
          </Column>
        </Row>
        <Row>
          <Column>
            <StyledH1
              css={css`
                padding-right: 30%;
                margin-bottom: -50px;
                ${mobile} {
                  padding-right: 0;
                  margin-bottom: 30px;
                }
              `}
            >
              Soms komt het gewoon nét even niet uit
            </StyledH1>
            <img
              css={css`
                align-self: center;
                max-width: 100%;
                overflow: auto;
                ${mobile} {
                  display: none;
                }
              `}
              src={questionsDesktop}
              alt=""
            />
            <img
              css={css`
                align-self: center;
                max-width: 100%;
                overflow: auto;
                ${desktop} {
                  display: none;
                }
              `}
              src={questionsMobile}
              alt=""
            />
          </Column>
        </Row>
        <Row id="sign-up">
          <Column
            css={css`
              align-items: center !important;
            `}
          >
            {shownForm === "signup" && (
              <Fragment>
                <H1>Maak hier je account aan</H1>
                <div
                  css={css`
                    width: 100%;
                    max-width: 850px;
                  `}
                >
                  <StyledInput
                    value={signupFormData.firstName}
                    onChange={(e) =>
                      setSignupformData({
                        ...signupFormData,
                        firstName: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Voornaam"
                  />
                  <StyledInput
                    value={signupFormData.lastName}
                    onChange={(e) =>
                      setSignupformData({
                        ...signupFormData,
                        lastName: e.target.value,
                      })
                    }
                    type="text"
                    placeholder="Achternaam"
                  />
                  {/* <StyledInput
                    value={signupFormData.phone}
                    onChange={(e) =>
                      setSignupformData({
                        ...signupFormData,
                        phone: e.target.value,
                      })
                    }
                    type="tel"
                    placeholder="* Telefoonnummer"
                  /> */}
                  <StyledInput
                    value={signupFormData.email}
                    onChange={(e) =>
                      setSignupformData({
                        ...signupFormData,
                        email: e.target.value,
                      })
                    }
                    type="email"
                    placeholder="* Email"
                  />
                  <StyledInput
                    value={signupFormData.password}
                    onChange={(e) =>
                      setSignupformData({
                        ...signupFormData,
                        password: e.target.value,
                      })
                    }
                    type="password"
                    placeholder="* Wachtwoord"
                  />
                </div>
                <label
                  htmlFor="privacy"
                  css={css`
                    font-family: Byrd;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 35px;
                    letter-spacing: 0px;
                    text-align: center;
                    margin: 30px auto;
                    color: #00000080;
                    ${mobile} {
                      font-family: Byrd;
                      font-size: 15px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                      letter-spacing: 0px;
                      text-align: center;
                    }
                  `}
                >
                  {" "}
                  <input
                    type="checkbox"
                    id="privacy"
                    css={css`
                      margin-right: 8px;
                      background: transparent;
                    `}
                    onChange={(e) =>
                      setSignupformData({
                        ...signupFormData,
                        privacy: e.target.checked,
                      })
                    }
                  />
                  Ik ga akkoord met de{" "}
                  <a
                    css={css`
                      color: #00000080;
                    `}
                    href="https://static1.squarespace.com/static/601000fe6ba35667f0b9b436/t/609145f18397ea26797f7665/1620133362084/Privacyverklaring+Seev.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    privacyvoorwaarden van Seev
                  </a>
                  .
                </label>
                {error && (
                  <div
                    css={css`
                      color: #f00;
                      margin-bottom: 16px;
                      background: rgba(255, 255, 255, 0.3);
                      padding: 8px 32px;
                      border-radius: 16px;
                    `}
                  >
                    {error}
                  </div>
                )}
                <RoundButton
                  css={css`
                    opacity: ${signupFormData.email &&
                    // signupFormData.phone &&
                    signupFormData.privacy &&
                    signupFormData.password
                      ? 1
                      : 0.3};
                    cursor: ${signupFormData.email &&
                    // signupFormData.phone &&
                    signupFormData.privacy &&
                    signupFormData.password
                      ? "pointer"
                      : "default"};
                  `}
                  onClick={() =>
                    signupFormData.email &&
                    // signupFormData.phone &&
                    signupFormData.privacy &&
                    signupFormData.password &&
                    handleFormSubmit()
                  }
                >
                  Meld je hier aan
                </RoundButton>
                <div
                  onClick={() => setShownForm("signin")}
                  css={css`
                    font-family: Byrd;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 35px;
                    letter-spacing: 0px;
                    text-align: center;
                    text-decoration-line: underline;
                    margin: 30px auto;
                    cursor: pointer;
                    ${mobile} {
                      font-family: Byrd;
                      font-size: 25px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 35px;
                      letter-spacing: 0px;
                      text-align: center;
                    }
                  `}
                >
                  Heb je al een account? Klik dan hier.
                </div>
              </Fragment>
            )}
            {shownForm === "signin" && (
              <Fragment>
                <H1>Log in met je account</H1>
                <div
                  css={css`
                    width: 100%;
                    max-width: 850px;
                  `}
                >
                  <StyledInput
                    value={signinFormData.email}
                    onChange={(e) =>
                      setSigninFormData({
                        ...signinFormData,
                        email: e.target.value,
                      })
                    }
                    type="email"
                    placeholder="Email"
                  />
                  <StyledInput
                    value={signinFormData.password}
                    onChange={(e) =>
                      setSigninFormData({
                        ...signinFormData,
                        password: e.target.value,
                      })
                    }
                    type="password"
                    placeholder="Wachtwoord"
                  />
                </div>
                {error && (
                  <div
                    css={css`
                      color: #f00;
                      margin-bottom: 16px;
                      background: rgba(255, 255, 255, 0.3);
                      padding: 8px 32px;
                      border-radius: 16px;
                    `}
                  >
                    {error}
                  </div>
                )}
                <RoundButton
                  css={css`
                    opacity: ${signinFormData.email && signinFormData.password
                      ? 1
                      : 0.3};
                    cursor: ${signinFormData.email && signinFormData.password
                      ? "pointer"
                      : "default"};
                  `}
                  onClick={() =>
                    signinFormData.email &&
                    signinFormData.password &&
                    firebase.app
                      .auth()
                      .signInWithEmailAndPassword(
                        signinFormData.email,
                        signinFormData.password
                      )
                      .then(({ user }) => {
                        if (user) {
                          linkDemand(demandId, user)
                            .then(() => history.push("/demand-linked"))
                            .catch((e) => setError(e.message));
                        } else {
                          setError("no user");
                        }
                      })
                      .catch(({ message }) => setError(message))
                  }
                >
                  Log in
                </RoundButton>
                <div
                  onClick={() => setShownForm("signup")}
                  css={css`
                    font-family: Byrd;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 35px;
                    letter-spacing: 0px;
                    text-align: center;
                    text-decoration-line: underline;
                    margin: 30px auto;
                    cursor: pointer;
                    ${mobile} {
                      font-family: Byrd;
                      font-size: 25px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 35px;
                      letter-spacing: 0px;
                      text-align: center;
                    }
                  `}
                >
                  Nog geen account? Klik dan hier.
                </div>
              </Fragment>
            )}
          </Column>
        </Row>
        <Row
          css={css`
            padding-top: 60px;
            ${mobile} {
              padding-top: 0;
            }
          `}
        >
          <Column
            css={css`
              padding-right: 100px;
              ${mobile} {
                display: none;
              }
            `}
          >
            <img
              css={css`
                width: 100%;
              `}
              src={phone3d}
              alt=""
            />
          </Column>
          <Column>
            <StyledH1>No worries, met seev betaal je op jouw manier</StyledH1>
            <img
              css={css`
                width: 100%;
                margin: 30px 0;
                ${desktop} {
                  display: none;
                }
              `}
              src={phone3d}
              alt=""
            />
            <USP
              css={css`
                width: 80%;
              `}
            >
              <div>Betaal in delen</div>
            </USP>
            <USP
              css={css`
                width: 90%;
              `}
            >
              <div>Zonder extra kosten</div>
            </USP>
            <USP
              css={css`
                width: 100%;
              `}
            >
              <div>Wanneer jij dat wilt</div>
            </USP>
          </Column>
        </Row>
      </MainContainer>
      <Footer />
    </Fragment>
  );
};
