import styled from "@emotion/styled";
import { css } from "emotion";
import React, { useContext, useEffect, useState } from "react";
import { Dots } from "react-activity";
import { BsFillEyeFill } from "react-icons/bs";
import { FaChevronLeft, FaChevronRight, FaUserCircle } from "react-icons/fa";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { H1, MainContainer } from "../../components";
import { FlexBox } from "../../components/flex-box";
import { SearchBar } from "../../components/search-bar";
import { styleChevron } from "../../components/styleChevron";
import { AppContext } from "../../modules/core";
import { UserContext } from "../../modules/user";
import { Debitor } from "../../shared";
import { getDocuments, useHistoryState } from "../../utils";

const ListItem = styled(Link)`
  display: block;
  text-decoration: none;
  color: #000;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  cursor: pointer;
  &:hover {
    background-color: #555;
    color: #ffffff;
  }
`;
const Name = styled.span`
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 700;
  margin: 0 16px;
`;
const ListItemDataContainer = styled.div`
  display: flex;
  align-items: center;
`;
const ListItemData = styled.div`
  display: inline-block;
  margin: 0 16px;
`;
const Pager = styled.div`
  margin-top: 38px;
  color: #9b9b9b;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
`;

const CheckboxContainer = styled.label`
  color: #000000;
  font-family: "Nunito Sans";
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  line-height: normal;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 16px;
`;

const ChevronRight = styleChevron(FaChevronRight);
const ChevronLeft = styleChevron(FaChevronLeft);

type LocationStateType = {
  query?: string;
  submittedQuery?: string;
  filter?: number;
  page?: number;
};

export const DebitorList: React.FC<
  RouteComponentProps<{}, {}, LocationStateType>
> = ({ location }) => {
  const { firebase } = useContext(AppContext);
  const [debitors, setDebitors] = useState<FirestoreDocument<Debitor>[] | null>(
    null
  );
  const [filteredDebitors, setFilteredDebitors] = useState<
    FirestoreDocument<Debitor>[] | null
  >(null);
  const [shownDebitors, setShownDebitors] = useState<
    FirestoreDocument<Debitor>[]
  >([]);
  const [loaded, setLoaded] = useState<boolean>();
  const [error, setError] = useState();
  const { user } = useContext(UserContext);
  const [creditorIds, setCreditorIds] = useState<string[] | null>(null);
  const history = useHistory<LocationStateType>();

  const [query, setQuery] = useHistoryState<string, LocationStateType>(
    history,
    location,
    "query",
    ""
  );
  const [submittedQuery, setSubmittedQuery] = useHistoryState<
    string,
    LocationStateType
  >(history, location, "submittedQuery", "");
  const [page, setPage] = useHistoryState<number, LocationStateType>(
    history,
    location,
    "page",
    0
  );

  const [isPilot1Selected, setIsPilot1Selected] = useState(false);
  const [isPilot2Selected, setIsPilot2Selected] = useState(false);
  const [isHasLinkedAccountSelected, setIsHasLinkedAccountSelected] =
    useState(false);
  const [isHasPlannedDemandSelected, setIsHasPlannedDemandSelected] =
    useState(false);
  const [isHasUnplannedDemandSelected, setIsHasUnplannedDemandSelected] =
    useState(false);
  const [isHasPaidThroughAppSelected, setIsHasPaidThroughAppSelected] =
    useState(false);
  const [isHasPaidThroughWebSelected, setIsHasPaidThroughWebSelected] =
    useState(false);
  const [isHasWarningSelected, setIsHasWarningSelected] = useState(false);

  useEffect(() => {
    if (user && user.creditors && creditorIds === null) {
      setCreditorIds(user.creditors.map((creditor) => creditor.id));
    }
  }, [user, creditorIds]);

  useEffect(() => {
    if (loaded || creditorIds === null) {
      return;
    }
    const getDebitors = async () => {
      try {
        let query = firebase.db
          .collection("debitors")
          .where("creditorId", "in", creditorIds);

        if (isPilot1Selected) {
          query = query.where("pilot1", "==", true);
        }
        if (isPilot2Selected) {
          query = query.where("pilot2", "==", true);
        }
        if (isHasLinkedAccountSelected) {
          query = query.where("hasLinkedAccount", "==", true);
        }
        if (isHasPlannedDemandSelected) {
          query = query.where("hasPlannedDemand", "==", true);
        }
        if (isHasUnplannedDemandSelected) {
          query = query.where("hasUnplannedDemand", "==", true);
        }
        if (isHasPaidThroughAppSelected) {
          query = query.where("hasPaidThroughApp", "==", true);
        }
        if (isHasPaidThroughWebSelected) {
          query = query.where("hasPaidThroughWeb", "==", true);
        }
        if (isHasWarningSelected) {
          query = query.where("hasWarning", "==", true);
        }

        const debitors = await getDocuments<Debitor>(query);
        setLoaded(true);
        setDebitors(debitors);
      } catch (e: any) {
        setError(e.message);
        setLoaded(true);
      }
    };
    getDebitors();
  }, [
    firebase.db,
    creditorIds,
    loaded,
    isPilot1Selected,
    isPilot2Selected,
    isHasLinkedAccountSelected,
    isHasPlannedDemandSelected,
    isHasUnplannedDemandSelected,
    isHasPaidThroughAppSelected,
    isHasPaidThroughWebSelected,
    isHasWarningSelected,
  ]);

  useEffect(() => {
    if (debitors === null) {
      return;
    }
    setFilteredDebitors(
      debitors.filter((debitor) => {
        const { firstName, lastName, externalReference, demands } =
          debitor.data;
        return `${firstName} ${lastName} ${externalReference} ${
          demands &&
          demands
            .map(
              (demand) =>
                `${demand.demandExternalReference} ${demand.totalAmount}`
            )
            .join(" ")
        }`
          .toLowerCase()
          .includes(submittedQuery.toLowerCase());
      })
    );
  }, [debitors, submittedQuery]);

  useEffect(() => {
    if (filteredDebitors === null) {
      return;
    }
    if (loaded && page >= Math.ceil(filteredDebitors.length / 7)) {
      setPage(Math.floor(filteredDebitors.length / 7));
    }
    setShownDebitors(filteredDebitors.slice(page * 7, page * 7 + 7));
  }, [filteredDebitors, page, setPage, loaded]);

  return (
    <MainContainer backLink="/admin" background="#eeeeee">
      <FlexBox className="j-space-between a-center">
        <H1>Deelnemers</H1>
      </FlexBox>

      <FlexBox className="j-space-between a-center">
        <BsFillEyeFill
          style={{
            width: 14,
            color: "#9b9b9b",
            marginRight: 26,
          }}
        />

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="Pilot1-checkbox"
            checked={isPilot1Selected}
            onChange={(e) => {
              setIsPilot1Selected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="Pilot1-checkbox">Pilot1</label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="Pilot2-checkbox"
            checked={isPilot2Selected}
            onChange={(e) => {
              setIsPilot2Selected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="Pilot2-checkbox">Pilot2</label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="HasLinkedAccount-checkbox"
            checked={isHasLinkedAccountSelected}
            onChange={(e) => {
              setIsHasLinkedAccountSelected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="HasLinkedAccount-checkbox">HasLinkedAccount</label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="HasPlannedDemand-checkbox"
            checked={isHasPlannedDemandSelected}
            onChange={(e) => {
              setIsHasPlannedDemandSelected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="HasPlannedDemand-checkbox">HasPlannedDemand</label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="HasUnplannedDemand-checkbox"
            checked={isHasUnplannedDemandSelected}
            onChange={(e) => {
              setIsHasUnplannedDemandSelected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="HasUnplannedDemand-checkbox">
            HasUnplannedDemand
          </label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="HasPaidThroughApp-checkbox"
            checked={isHasPaidThroughAppSelected}
            onChange={(e) => {
              setIsHasPaidThroughAppSelected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="HasPaidThroughApp-checkbox">HasPaidThroughApp</label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="HasPaidThroughWeb-checkbox"
            checked={isHasPaidThroughWebSelected}
            onChange={(e) => {
              setIsHasPaidThroughWebSelected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="HasPaidThroughWeb-checkbox">HasPaidThroughWeb</label>
        </CheckboxContainer>

        <CheckboxContainer
          css={css`
            font-size: 10px;
            margin: 0px 8px;
          `}
        >
          <input
            type="checkbox"
            id="HasWarning-checkbox"
            checked={isHasWarningSelected}
            onChange={(e) => {
              setIsHasWarningSelected(e.target.checked);
              setLoaded(false);
            }}
          />
          <label htmlFor="HasWarning-checkbox">HasWarning</label>
        </CheckboxContainer>
      </FlexBox>
      <SearchBar
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onSubmit={() => setSubmittedQuery(query)}
        placeholder="Zoek deelnemer"
      />
      {filteredDebitors && <div>{filteredDebitors.length} debiteuren</div>}
      {!loaded && <Dots />}
      {error && error}
      {shownDebitors.map((debitor) => {
        const { firstName, lastName, externalReference } = debitor.data;
        return (
          <ListItem
            key={`debitor-${debitor.id}`}
            to={`/admin/debitors/${debitor.id}`}
          >
            <ListItemDataContainer>
              {/* <UserIconImg src={iconUser} alt="" /> */}
              <FaUserCircle
                className={css`
                  color: #9b9b9b;
                  font-size: 26px;
                `}
              />
              <Name>
                {firstName} {lastName}
              </Name>
            </ListItemDataContainer>
            <ListItemDataContainer>
              <ListItemData>{externalReference}</ListItemData>
              {/* <img src={caretRight} alt="" /> */}
              <FaChevronRight />
            </ListItemDataContainer>
          </ListItem>
        );
      })}
      {filteredDebitors && (
        <Pager>
          {page > 0 && <ChevronLeft onClick={() => setPage(page - 1)} />}
          Pagina {page + 1} van {Math.ceil(filteredDebitors.length / 7)}{" "}
          {page * 7 + 7 < filteredDebitors.length && (
            <ChevronRight onClick={() => setPage(page + 1)} />
          )}
        </Pager>
      )}
    </MainContainer>
  );
};
