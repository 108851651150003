import { useState, useCallback, useEffect } from "react";
import { History, Location } from "history";

export const useHistoryState = <T, LST extends object>(
  history: History<LST>,
  location: Location<LST>,
  key: keyof LST,
  initialValue: T
) => {
  const [state, setState] = useState<T>(initialValue);
  const setHistoryState = useCallback(
    (value: T) => {
      setState(value);
      history.replace(history.location.pathname, {
        ...history.location.state,
        [key]: value,
      });
    },
    [history, key]
  );
  useEffect(() => {
    if (location.state) {
      //@ts-ignore
      if (location.state[key] !== undefined) {
        //@ts-ignore
        setState(location.state[key]);
      }
    }
  }, [location, key]);
  return [state, setHistoryState] as [T, (value: T) => void];
};
