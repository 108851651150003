import styled from "@emotion/styled";
import axios from "axios";
import React, { FC, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/react-activity.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { H1, MainContainer, Section } from "../../components";
import { ActionButton } from "../../components/ActionButton";
import { environment } from "../../modules/config";

const InputLabel = styled.div`
  margin-bottom: 4px;
`;

const StyledInput = styled.input`
  margin-bottom: 16px;
  width: 100%;
  padding: 8px;
`;

const StyledDatePicker = styled(DatePicker)`
  input {
    margin-bottom: 16px;
    width: 100%;
    padding: 8px;
  }
`;

const AddDemand: FC = () => {
  const addDemandEndpoint = `https://europe-west1-${environment.FIREBASE_PROJECTID}.cloudfunctions.net/addDemand`;
  const [demandData, setDemandData] = useState({
    firstName: "",
    lastName: "",
    totalAmount: 0,
    demandExternalReference: "demand-",
    debitorExternalReference: "debitor-",
    issuedAt: new Date(),
    dueDate: new Date(),
  });
  const [responseData, setResponseData] = useState<any>();
  const [loading, setLoading] = useState(false);
  async function addDemand() {
    setResponseData(null);
    setLoading(true);
    try {
      const response = await axios.post(addDemandEndpoint, {
        ...demandData,
        issuedAt: demandData.issuedAt.toISOString().substr(0, 10),
        dueDate: demandData.dueDate.toISOString().substr(0, 10),
      });
      console.log("response", response);
      setLoading(false);
      setResponseData(response.data);
    } catch (e) {
      console.error(e);
    }
  }
  return (
    <MainContainer backLink="/admin">
      <H1>Betalingsverzoek toevoegen</H1>
      <Section>
        <InputLabel>Voornaam</InputLabel>
        <div>
          <StyledInput
            type="text"
            value={demandData.firstName}
            onChange={(e) =>
              setDemandData({ ...demandData, firstName: e.target.value })
            }
          />
        </div>
        <InputLabel>Achternaam</InputLabel>
        <div>
          <StyledInput
            type="text"
            value={demandData.lastName}
            onChange={(e) =>
              setDemandData({ ...demandData, lastName: e.target.value })
            }
          />
        </div>
        <InputLabel>Bedrag</InputLabel>
        <div>
          <StyledInput
            type="number"
            value={demandData.totalAmount}
            onChange={(e) =>
              setDemandData({
                ...demandData,
                totalAmount: parseFloat(e.target.value),
              })
            }
          />
        </div>
        <InputLabel>Factuurnummer</InputLabel>
        <div>
          <StyledInput
            type="text"
            value={demandData.demandExternalReference}
            onChange={(e) =>
              setDemandData({
                ...demandData,
                demandExternalReference: e.target.value,
              })
            }
          />
        </div>
        <InputLabel>Studentnummer</InputLabel>
        <div>
          <StyledInput
            type="text"
            value={demandData.debitorExternalReference}
            onChange={(e) =>
              setDemandData({
                ...demandData,
                debitorExternalReference: e.target.value,
              })
            }
          />
        </div>
        <InputLabel>Datum facturatie</InputLabel>
        <div>
          <StyledDatePicker
            selected={demandData.issuedAt}
            onChange={(issuedAt) =>
              issuedAt && setDemandData({ ...demandData, issuedAt })
            }
          />
        </div>
        <InputLabel>Uiterste betaaldatum</InputLabel>
        <div>
          <StyledDatePicker
            selected={demandData.dueDate}
            onChange={(dueDate) =>
              dueDate && setDemandData({ ...demandData, dueDate })
            }
          />
        </div>
      </Section>
      <ActionButton noMargin onClick={addDemand}>
        Toevoegen
      </ActionButton>
      <Section>
        {responseData ? (
          <div>
            <div>
              <a href={responseData.url}>{responseData.url}</a>
            </div>
            <div>
              <img alt={`qr code`} src={responseData.qrCode} />
            </div>
            <div>
              <a
                href={responseData.qrCode}
                download={`${responseData.debitorExternalReference}${responseData.firstName}${responseData.lastName}.png`.replace(
                  " ",
                  ""
                )}
              >
                {`${responseData.debitorExternalReference}${responseData.firstName}${responseData.lastName}.png`.replace(
                  " ",
                  ""
                )}
              </a>
            </div>
          </div>
        ) : (
          loading && (
            <div>
              <Dots />
            </div>
          )
        )}
      </Section>
    </MainContainer>
  );
};

export default AddDemand;
