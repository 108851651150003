import React, { useEffect, useState } from "react";
import { MainContainer } from "../components";

export const DeepLinkPage = () => {
  const [uri, setUri] = useState<null | string>();
  useEffect(() => {
    var qs = decodeURIComponent(document.location.search);
    let uri: string | null = null;
    if (qs) {
      uri = qs.split("?linkingUri=")[1];
    }
    console.log("uri", uri);
    if (uri) {
      setUri(uri);
      const timeout = setTimeout(() => {
        window.location.href = decodeURIComponent(uri!);
      }, 1000);
      return () => window.clearTimeout(timeout);
    }
  }, []);
  return (
    <MainContainer>
      <div>
        {uri && (
          <div>
            <div>Je wordt teruggestuurd naar Seev</div>
            <div>
              <a href={decodeURIComponent(uri)}>
                Klik hier als het te lang duurt.
              </a>
            </div>
          </div>
        )}
        {!uri && <div>no uri param provided</div>}
      </div>
    </MainContainer>
  );
};
