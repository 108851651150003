import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../modules/core";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import * as firebaseLib from "firebase";
import styled from "@emotion/styled";
import { monnieLogoLarge } from "../assets/images";

const LoginContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
`;

const MonnieLogoImage = styled.img`
  width: 320px;
  margin-bottom: 62px;
  max-width: calc(100% - 32px);
`;

const Login = () => {
  const { firebase } = useContext(AppContext);
  const history = useHistory();
  const uiConfig = {
    signInSuccessUrl: "/admin",
    callbacks: {
      signInSuccessWithAuthResult: (authResult: any, redirectUrl?: string) => {
        history.push("/admin");
        return false;
      },
    },
    signInOptions: [
      {
        provider: firebaseLib.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod:
          firebaseLib.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      },
    ],
  };

  return (
    <LoginContainer>
      <MonnieLogoImage src={monnieLogoLarge} />
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={firebase.app.auth()}
      />
    </LoginContainer>
  );
};

export default Login;
