import styled from "@emotion/styled";
import React, { FunctionComponent, ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { arrowBack } from "../assets/images";
import { ImgButton } from "../components";
import { Row } from "../components/flex-box";
import { FlexBox } from "./flex-box";

type ContainerProps = {
  moveUp?: boolean;
  background?: string;
};

const StaticContainer = styled.div<ContainerProps>`
  background: ${(props) =>
    props.background
      ? props.background
      : "linear-gradient(180deg, #4163ff 0%, #fd9ad7 78.19%)"};
`;

export const Container = styled(FlexBox)<ContainerProps>`
  position: absolute;
  top: ${(props) => (props.moveUp ? 0 : "73px")};
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) =>
    props.background
      ? props.background
      : "linear-gradient(180deg, #4163ff 0%, #fd9ad7 78.19%)"};
  justify-content: space-between;

  a {
    color: #000000;
  }
`;

export const Sidebar = styled.div`
  width: 460px;
  background: #fff;
  padding: 42px 16px 32px;
  height: 100%;
  overflow-y: auto;
`;

const StyledMainContainer = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  height: 100%;
  overflow-y: auto;
`;

const StyledMainContainerInner = styled.div<{ maxWidth?: string }>`
  display: flex;
  flex-direction: column;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "1440px")};
  width: 100%;
  padding: 48px;

  @media screen and (max-width: 640px) {
    padding: 16px;
  }
`;

type MainContainerProps = {
  sidebarContent?: JSX.Element | null;
  backLink?: string;
  moveUp?: boolean;
  background?: string;
  footer?: ReactNode;
  staticContainer?: boolean;
  maxWidth?: string;
};

export const MainContainer: FunctionComponent<MainContainerProps> = ({
  children,
  sidebarContent,
  backLink,
  moveUp = false,
  background,
  footer,
  staticContainer,
  maxWidth,
  ...props
}) => {
  const history = useHistory();
  const ContainerVariant = staticContainer ? StaticContainer : Container;

  return (
    <ContainerVariant moveUp background={background}>
      <StyledMainContainer {...props}>
        <StyledMainContainerInner maxWidth={maxWidth}>
          <Row>
            {backLink && (
              <FlexBox className="column">
                {/* <Link to={{ pathname: backLink }}> */}
                <ImgButton
                  onClick={() => history.goBack()}
                  src={arrowBack}
                ></ImgButton>
                {/* </Link> */}
              </FlexBox>
            )}
            <FlexBox className="column fill-remaining">{children}</FlexBox>
          </Row>
        </StyledMainContainerInner>
      </StyledMainContainer>
      {footer && footer}
      {sidebarContent && <Sidebar>{sidebarContent}</Sidebar>}
    </ContainerVariant>
  );
};
