import styled from "@emotion/styled";
import { css } from "emotion";
import React, { useContext, useEffect, useState } from "react";
import { FaFileExport, FaUsers } from "react-icons/fa";
import { IoMdClock, IoMdInformationCircle, IoMdPaper } from "react-icons/io";
import { Route, useHistory } from "react-router-dom";
import { Container } from "../../components";
import { ActionButton } from "../../components/ActionButton";
import { NavBar } from "../../components/nav-bar";
import { PopoutMenu } from "../../components/PopoutMenu";
import { AppContext } from "../../modules/core";
import { getFunctions } from "../../modules/firebase";
import { UserContext } from "../../modules/user";
import { downloadString } from "../../utils";
import AddDemand from "./add-demand";
import { DebitorDetail } from "./debitor-detail";
import { DebitorList } from "./debitor-list";
import { Info } from "./info";
import { PaymentList } from "./payment-list";

const UserMenu = styled(PopoutMenu)`
  font-size: 18px;
  font-weight: 700;
  border-left: 1px solid #999;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px 0 32px;
`;

const Admin = () => {
  const { firebase } = useContext(AppContext);
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [creditorName, setCreditorName] = useState<string>();
  const [creditorIds, setCreditorIds] = useState<string[]>([]);
  const [dataDumpLoading, setDataDumpLoading] = useState(false);
  useEffect(() => {
    if (typeof creditorName === "undefined" && user !== null) {
      if (!user.creditors || user.creditors.length === 0) {
        setCreditorName("No Creditor Assigned");
        return;
      }
      if (user.creditors.length === 1) {
        setCreditorName(user.creditors[0].name);
        return;
      }
      setCreditorName("Admin");
    }
  }, [user, creditorName]);
  useEffect(() => {
    if (user) {
      setCreditorIds(user.creditorIds ? user.creditorIds : []);
    }
  }, [user]);
  return (
    <>
      <NavBar>
        <h1
          className={css`
            cursor: pointer;
          `}
          onClick={() => history.push("/admin")}
        >
          Seev crediteurendashboard
        </h1>
        <UserMenu
          title={
            <span
              className={css`
                display: inline-block;
                margin-right: 32px;
              `}
            >
              {creditorName ? creditorName : ""}
            </span>
          }
          items={[
            {
              name: user ? `${user?.firstName} ${user?.lastName}` : "",
              value: 1,
            },
            { name: "Uitloggen", value: 0 },
          ]}
          onMenuItemClick={(item) =>
            item.value === 0 && firebase.app.auth().signOut()
          }
        ></UserMenu>
      </NavBar>
      <>
        <Route exact path="/admin/payments" component={PaymentList} />
        <Route path="/admin/add-demand" component={AddDemand} />
        <Route path="/admin/info" component={Info} />
        <Route exact path="/admin/debitors" component={DebitorList} />
        <Route path="/admin/debitors/:debitorId" component={DebitorDetail} />
        <Route exact path="/admin">
          <Container
            className={css`
              justify-content: center;
              align-items: center;
            `}
          >
            <div
              className={css`
                padding: 16px;
                flex-wrap: wrap;
                display: flex;
                width: 100%;
                max-width: 800px;
              `}
            >
              <ActionButton
                chevron
                className={css`
                  width: calc(50% - 24px);
                `}
                icon={<FaFileExport />}
                onClick={() => history.push("/admin/add-demand")}
              >
                Factuur toevoegen
              </ActionButton>
              <ActionButton
                chevron
                className={css`
                  width: calc(50% - 24px);
                `}
                icon={<FaUsers />}
                onClick={() => history.push("/admin/debitors")}
              >
                Deelnemersoverzicht
              </ActionButton>
              <ActionButton
                chevron
                className={css`
                  width: calc(50% - 24px);
                `}
                icon={<IoMdInformationCircle />}
                onClick={() => history.push("/admin/info")}
              >
                Informatie
              </ActionButton>
              {dataDumpLoading && (
                <ActionButton
                  chevron
                  disabled
                  className={css`
                    width: calc(50% - 24px);
                  `}
                  icon={<IoMdClock />}
                >
                  Een momentje...
                </ActionButton>
              )}
              {!dataDumpLoading && (
                <ActionButton
                  chevron
                  className={css`
                    width: calc(50% - 24px);
                  `}
                  icon={<IoMdPaper />}
                  onClick={async () => {
                    try {
                      setDataDumpLoading(true);
                      const result = await getFunctions().httpsCallable(
                        "dataDump"
                      )({
                        creditorIds,
                      });
                      downloadString(
                        result.data.dump,
                        "text/csv",
                        `data-dump-${new Date().toDateString()}.csv`
                      );
                      setDataDumpLoading(false);
                    } catch (e) {
                      console.error(e);
                      alert(JSON.stringify(e));
                      setDataDumpLoading(false);
                    }
                  }}
                >
                  Data dump
                </ActionButton>
              )}
            </div>
          </Container>
        </Route>
      </>
    </>
  );
};

export default Admin;
