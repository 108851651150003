import * as firebase from "firebase/app";

// These imports load individual services into the firebase namespace.
import "firebase/auth";
import "firebase/firestore";
import { environment } from "../config";

/*console.log(`Initialize Firebase user app with key: ${apiKey}`);*/

export const app = firebase.initializeApp({
  apiKey: environment.FIREBASE_APIKEY,
  authDomain: environment.FIREBASE_AUTHDOMAIN,
  projectId: environment.FIREBASE_PROJECTID,
  databaseURL: environment.FIREBASE_DATABASEURL,
});

export function getFunctions() {
  return app.functions("europe-west1");
}

export function getCurrentUser(): firebase.User | null {
  return app.auth().currentUser;
}

export function getCurrentUserId(): string | undefined {
  const currentUser = getCurrentUser();
  return currentUser ? currentUser.uid : undefined;
}

export const db = firebase.firestore(app);
export const FieldValue = firebase.firestore.FieldValue;

export interface FirebaseModuleApi {
  app: firebase.app.App;
  getCurrentUser: () => firebase.User | null;
  getCurrentUserId: () => string | undefined;
  db: firebase.firestore.Firestore;
}
