import { DocumentSnapshot } from "@google-cloud/firestore";
import { firestore } from "firebase";
import invariant from "invariant";
import { get, last } from "lodash";

const BATCH_LIMIT = 500;

export async function getDocument<T>(
  db: firestore.Firestore,
  collectionName: string,
  documentId: string
): Promise<FirestoreDocument<T>> {
  const doc = await db.collection(collectionName).doc(documentId).get();

  invariant(
    doc.exists,
    `No document ${documentId} available in collection ${collectionName}`
  );

  return { id: doc.id, data: doc.data() as T };
}

export async function getDocuments<T>(
  query: firestore.Query | firestore.CollectionReference,
  options: {
    orderByField?: string;
    batchSize?: number;
    startAfter?: DocumentSnapshot;
    limitToFirstBatch?: boolean;
  } = {}
): Promise<FirestoreDocument<T>[]> {
  const {
    orderByField,
    batchSize = BATCH_LIMIT,
    startAfter,
    limitToFirstBatch,
  } = options;

  if (orderByField) {
    query = query.orderBy(orderByField);
  }
  if (startAfter) {
    query = query.startAfter(startAfter);
  }
  query = query.limit(batchSize);

  return getDocumentsBatch<T>(query, {
    batchSize,
    orderByField,
    limitToFirstBatch,
  });
}

async function getDocumentsBatch<T>(
  query: firestore.Query,
  options: {
    batchSize: number;
    orderByField?: string;
    limitToFirstBatch?: boolean;
    withLastSnapshot?: boolean;
  }
): Promise<FirestoreDocument<T>[]> {
  const { orderByField, limitToFirstBatch } = options;

  /**
   * For easy testing we sometimes need to run an algorithm on only a part of
   * a collection (like cities). This boolean makes that easy but it should
   * never be used in production so we log it with a warning.
   */
  if (limitToFirstBatch) {
    console.warn(
      "Returning only the first batch of documents (limitToFirstBatch = true)"
    );
  }

  const snapshot = await query.get();

  if (snapshot.empty) {
    return [];
  }

  const lastDoc = last(snapshot.docs) as firestore.QueryDocumentSnapshot;

  /**
   * Map the results to documents
   */
  const results = snapshot.docs.map((doc) => ({
    id: doc.id,
    data: doc.data() as T,
  }));

  /**
   * Log some information about count and pagination
   */
  const numRead = snapshot.size;
  const lastPageLog = orderByField && get(lastDoc.data(), orderByField);
  console.log(`Read ${numRead} records, until ${lastPageLog || lastDoc.id}`);

  if (numRead < BATCH_LIMIT || limitToFirstBatch === true) {
    return results;
  } else {
    const pagedQuery = query.startAfter(lastDoc);
    return results.concat(await getDocumentsBatch<T>(pagedQuery, options));
  }
}

export async function batchedDelete(
  db: firestore.Firestore,
  query: firestore.Query
): Promise<void> {
  const limitedQuery = query.limit(BATCH_LIMIT);

  await deleteQueryBatch(db, limitedQuery, BATCH_LIMIT);
}

async function deleteQueryBatch(
  db: firestore.Firestore,
  query: firestore.Query,
  batchSize: number
) {
  const snapshot = await query.get();

  if (snapshot.size === 0) {
    return;
  }

  const batch = db.batch();

  snapshot.docs.forEach((doc) => {
    batch.delete(doc.ref);
  });

  await batch.commit();

  const numDeleted = snapshot.size;
  console.log(`Deleted ${numDeleted} records`);

  if (numDeleted === 0) {
    return;
  }

  await deleteQueryBatch(db, query, batchSize);
}

export async function getAllDocumentsForCollection<T>(
  db: firestore.Firestore,
  collectionName: string
): Promise<FirestoreDocument<T>[]> {
  const query = db.collection(collectionName);
  return getDocuments<T>(query);
}

export async function getAllDocumentIdsForCollection(
  db: firestore.Firestore,
  collectionName: string
): Promise<string[]> {
  const query = db.collection(collectionName);
  const docs = await getDocuments<any>(query);
  return docs.map((doc) => doc.id);
}
