import styled from "@emotion/styled";
import { mobile } from "../utils";

export const FlexBox = styled.div`
  display: flex;
  &.fill-remaining {
    flex: 1;
  }
  &.column {
    flex-direction: column;
  }
  &.j-space-between {
    justify-content: space-between;
  }
  &.j-space-around {
    justify-content: space-around;
  }
  &.j-center {
    justify-content: center;
  }
  &.a-start {
    align-items: flex-start;
  }
  &.a-space-between {
    align-items: space-between;
  }
  &.a-space-around {
    align-items: space-around;
  }
  &.a-center {
    align-items: center;
  }
  &.padded {
    padding: 10px 10px;
  }
  &.padded-v {
    padding: 0 10px;
  }
  &.padded-h {
    padding: 10px 0;
  }
  &.margin-bottom {
    margin-bottom: 16px;
  }
`;

export const Row = styled(FlexBox)<{ mobileReverse?: boolean }>`
  & > * {
    margin-right: 16px;
    margin-bottom: 64px;
    &:last-child {
      margin-right: 0px;
    }
  }
  ${mobile} {
    flex-direction: ${(props) =>
      props.mobileReverse ? "column-reverse" : "column"};
    margin-bottom: 30px;
    & > * {
      margin-right: 0;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const RowReverse = styled(FlexBox)`
  flex-direction: row-reverse;
  & > * {
    margin-left: 16px;
    &:first-of-type {
      margin-left: 0px;
    }
  }
`;

export const Column = styled(FlexBox)`
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`;
